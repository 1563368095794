import { eventManager } from 'event-manager'
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { SetRequired } from 'type-fest'

import { useSetPermissions } from './use-permissions'
import { apiClient, ApiError } from 'main/services/api/api-client'
import { apiClientCompat_UNSTABLE } from 'main/services/api/api-client-unstable'
import { ConfigModel } from 'main/data-access'

export type SavedView = {
  account_id: number
  default_saved_view_group_order: number
  global: boolean
  id: number
  name: string
  query_string: string
  resource_type: string
  saved_view_group_id?: number | null
  user_id: string | number // why was this string?
}

export type SavedViewGroup = {
  account_id: number
  id: number
  name: string
  public: boolean
  saved_views: SavedView[]
  user_id: number
}

export type SavedViewResponse = {
  meta: {}
  filter: any[]
}

export type SavedViewGroupsResponse = {
  meta: {
    permissions: {
      create: number[]
      destroy: number[]
      update: number[]
    }
  }
  saved_view_groups: SavedViewGroup[]
}

let controller: AbortController | undefined

export function useSavedViewGroups(
  accountId: string | number,
  opts: UseQueryOptions<SavedViewGroupsResponse, ApiError> = {}
) {
  const isReactRunbookEnabled = ConfigModel.isFeatureEnabled('react_runbook')
  const client = isReactRunbookEnabled ? (apiClientCompat_UNSTABLE as typeof apiClient) : apiClient

  const setPermissions = useSetPermissions('saved-view-group')

  return useQuery<SavedViewGroupsResponse, ApiError>(
    ['accounts', String(accountId), 'saved_view_groups'],
    async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      const signal = controller.signal

      const { data } = await client.get<SavedViewGroupsResponse>({
        url: `accounts/${accountId}/saved_view_groups`,
        config: isReactRunbookEnabled
          ? {
              // @ts-ignore
              signal
            }
          : undefined
      })

      return data
    },
    {
      ...opts,
      onSuccess: data => {
        opts.onSuccess?.(data)
        setPermissions(data.meta.permissions)
      }
    }
  )
}

type CreateSavedViewGroupResponse = {
  meta: {}
  saved_view_group: SavedViewGroup
}

type CreateSavedViewGroupPayload = SetRequired<Partial<SavedViewGroup>, 'name' | 'public' | 'user_id'>

export const useCreateSavedViewGroup = (
  accountId: string | number,
  options: UseMutationOptions<CreateSavedViewGroupResponse, ApiError, CreateSavedViewGroupPayload> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<CreateSavedViewGroupResponse, ApiError, CreateSavedViewGroupPayload>(
    async payload => {
      const response = await apiClient.post<
        { saved_view_group: CreateSavedViewGroupPayload },
        CreateSavedViewGroupResponse
      >({
        url: 'saved_view_groups',
        data: { saved_view_group: { account_id: Number(accountId), ...payload } }
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onMutate: payload => {
        const existingSavedViewGroupsData = queryClient.getQueryData<SavedViewGroupsResponse>([
          'accounts',
          String(accountId),
          'saved_view_groups'
        ])

        const updatedSavedViewGroups = [
          ...(existingSavedViewGroupsData?.saved_view_groups || []),
          { saved_views: [], ...payload, account_id: Number(accountId), id: 0 }
        ]

        if (existingSavedViewGroupsData && updatedSavedViewGroups) {
          queryClient.setQueryData<SavedViewGroupsResponse>(['accounts', String(accountId), 'saved_view_groups'], {
            ...existingSavedViewGroupsData,
            saved_view_groups: updatedSavedViewGroups
          })
        }

        options.onMutate?.(payload)
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        eventManager.emit('trigger-angular-fetch-saved-views', { accountId: Number(accountId), reset: true })
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}

type DeleteSavedViewGroupResponse = {
  meta: {}
  saved_view_group: SavedViewGroup
}

export const useDeleteSavedViewGroup = (
  accountId: string | number,
  options: UseMutationOptions<DeleteSavedViewGroupResponse, ApiError, string | number> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<DeleteSavedViewGroupResponse, ApiError, string | number>(
    async (id: string | number) => {
      // get is account specific but delete is not
      const response = await apiClient.delete<DeleteSavedViewGroupResponse>({
        url: `saved_view_groups/${id}`
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onMutate: payload => {
        const existingSavedViewGroupsData = queryClient.getQueryData<SavedViewGroupsResponse>([
          'accounts',
          String(accountId),
          'saved_view_groups'
        ])

        const updatedSavedViewGroups = existingSavedViewGroupsData?.saved_view_groups.filter(
          group => group.id !== Number(payload)
        )

        if (existingSavedViewGroupsData && updatedSavedViewGroups) {
          queryClient.setQueryData<SavedViewGroupsResponse>(['accounts', String(accountId), 'saved_view_groups'], {
            ...existingSavedViewGroupsData,
            saved_view_groups: updatedSavedViewGroups
          })
        }

        options.onMutate?.(payload)
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}

type UpdateSavedViewGroupPayload = SetRequired<Partial<SavedViewGroup>, 'id'>
type UpdateSavedViewGroupResponse = {
  meta: {}
  saved_view_group: SavedViewGroup
}

export const useUpdateSavedViewGroup = (
  accountId: string | number,
  options: UseMutationOptions<UpdateSavedViewGroupResponse, ApiError, UpdateSavedViewGroupPayload> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<UpdateSavedViewGroupResponse, ApiError, UpdateSavedViewGroupPayload>(
    async payload => {
      const response = await apiClient.put<
        { saved_view_group: UpdateSavedViewGroupPayload },
        UpdateSavedViewGroupResponse
      >({
        url: `saved_view_groups/${payload.id}`,
        data: { saved_view_group: payload }
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onMutate: payload => {
        const existingSavedViewGroupsData = queryClient.getQueryData<SavedViewGroupsResponse>([
          'accounts',
          String(accountId),
          'saved_view_groups'
        ])

        // only use them from the index endpoint... if we use them from the show we'd want to optimistically update the
        // individual query cache
        const updatedSavedViewGroups = existingSavedViewGroupsData?.saved_view_groups.map(group =>
          group.id === payload.id ? { ...group, ...payload } : group
        )

        if (existingSavedViewGroupsData && updatedSavedViewGroups) {
          queryClient.setQueryData<SavedViewGroupsResponse>(['accounts', String(accountId), 'saved_view_groups'], {
            ...existingSavedViewGroupsData,
            saved_view_groups: updatedSavedViewGroups
          })
        }

        options.onMutate?.(payload)
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}

type CreateSavedViewPayload = Partial<SavedView>
export type CreateSavedViewResponse = { filter: SavedView }

export const useCreateSavedView = (
  accountId?: number,
  options: UseMutationOptions<CreateSavedViewResponse, ApiError, CreateSavedViewPayload> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<CreateSavedViewResponse, ApiError, CreateSavedViewPayload>(
    async (payload: CreateSavedViewPayload) => {
      const { data } = await apiClient.post<{ filter: CreateSavedViewPayload }, CreateSavedViewResponse>({
        url: 'filters',
        data: { filter: payload }
      })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data!
    },
    {
      ...options,
      onMutate: data => {
        // saved view group id is undefined when saving to a private saved view group
        // -2 is the group id assigned to a private saved view
        const savedViewGroupId = data.saved_view_group_id ?? -2
        queryClient.setQueryData<SavedViewGroupsResponse | undefined>(
          ['accounts', String(data.account_id), 'saved_view_groups'],
          oldData => {
            const savedViewGroup = oldData?.saved_view_groups.find(group => group.id === savedViewGroupId)
            if (oldData && savedViewGroup) {
              // need to cast so that the mutate partial can be used to show the name in the sidebar immediately
              const newSavedViews = [...(savedViewGroup?.saved_views ?? {}), data] as SavedView[]
              const newSavedViewGroups = oldData.saved_view_groups.map(group => {
                return {
                  ...group,
                  saved_views: group.id === savedViewGroup.id ? newSavedViews : group.saved_views
                }
              })
              return { ...oldData, saved_view_groups: newSavedViewGroups }
            } else {
              return oldData
            }
          }
        )
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}

type UpdateSavedViewPayload = SetRequired<Partial<SavedView>, 'id'>
type UpdateSavedViewResponse = { filter: SavedView }

export const useUpdateSavedView = (
  accountId: string | number,
  options: UseMutationOptions<UpdateSavedViewResponse, ApiError, UpdateSavedViewPayload> = {}
) => {
  const queryClient = useQueryClient()

  return useMutation<UpdateSavedViewResponse, ApiError, UpdateSavedViewPayload>(
    async (payload: UpdateSavedViewPayload) => {
      const response = await apiClient.put<{ filter: UpdateSavedViewPayload }, UpdateSavedViewResponse>({
        url: `filters/${payload.id}`,
        data: { filter: payload }
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onMutate: payload => {
        const existingSavedViewGroupsData = queryClient.getQueryData<SavedViewGroupsResponse>([
          'accounts',
          String(accountId),
          'saved_view_groups'
        ])

        // nested map...
        const updatedSavedViewGroups = existingSavedViewGroupsData?.saved_view_groups.map(group => {
          group.saved_views = group.saved_views.map(view => ({
            ...view,
            ...(view.id === payload.id ? payload : {})
          }))
          return group
        })

        if (existingSavedViewGroupsData && updatedSavedViewGroups) {
          queryClient.setQueryData<SavedViewGroupsResponse>(['accounts', String(accountId), 'saved_view_groups'], {
            ...existingSavedViewGroupsData,
            saved_view_groups: updatedSavedViewGroups
          })
        }

        options.onMutate?.(payload)
      },
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        eventManager.emit('trigger-angular-fetch-saved-views', { accountId: Number(accountId), reset: true })
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}

export const useDeleteSavedView = (
  accountId: string | number,
  options: UseMutationOptions<SavedView, ApiError, string | number> = {}
) => {
  const queryClient = useQueryClient()

  // TODO: optimistic updates
  return useMutation<SavedView, ApiError, string | number>(
    async (id: string | number) => {
      const response = await apiClient.delete<SavedView>({
        url: `filters/${id}`
      })

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return response.data!
    },
    {
      ...options,
      onSettled: (data, error, variables, context) => {
        queryClient.invalidateQueries(['accounts', String(accountId), 'saved_view_groups'])
        eventManager.emit('trigger-angular-fetch-saved-views', { accountId: Number(accountId), reset: true })
        options.onSettled?.(data, error, variables, context)
      }
    }
  )
}
