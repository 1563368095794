import { ReactNode, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import i18n from 'config/i18n'
import { createRoutesFromChildren, HashRouter, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import { UiProvider } from '@cutover/react-ui'
import { RecoilRoot, RecoilRootProps } from 'main/recoil/data-access/recoil-exports'
import { GlobalConfigProvider } from './global-config-provider'
import { LanguageProvider } from './language-provider'
import { LoadingStateProvider } from './loading-state-provider'
import { WebsocketsProvider } from './websockets-provider'
import { AngularAppControllerReadyProvider } from '../connectors/angular-app-controller-ready-provider'
import { GlobalConfigType } from '../services/hooks'
import { QueryClientProvider } from 'main/context/query-client-provider'
import { queryClient } from 'main/query-client'
import { accountSlugUrlParamState, runbookUrlParamState, runbookVersionUrlParamState } from 'main/recoil/runbook'
import { parseResourceURLParams } from 'main/services/params-util'
import { IntercomProviderWrapper } from 'main/components/support-and-analytics/intercom'
import { ConfigModel } from 'main/data-access'

type AppProvidersProps = {
  children: ReactNode
  queryClient?: typeof queryClient
  i18n?: typeof i18n
  withRouter?: boolean
  config?: GlobalConfigType
  fullScreen?: boolean
  themeMode?: 'light' | 'dark'
  reactApp?: 'default' | 'legacy'
  recoilProps?: RecoilRootProps
}

export function AppProviders({
  children,
  queryClient: queryClientInstance = queryClient,
  i18n: i18nInstance = i18n,
  withRouter = true,
  config,
  fullScreen,
  themeMode = 'light',
  reactApp = 'default',
  recoilProps
}: AppProvidersProps) {
  const content = (
    <AngularAppControllerReadyProvider>
      <RecoilWrapper recoilProps={recoilProps}>
        <QueryClientProvider client={queryClientInstance} contextSharing withDevTools={reactApp !== 'legacy'}>
          <GlobalConfigProvider config={config}>
            <SentryProviderWrapper enabled={reactApp === 'default'}>
              <LoadingStateProviderWrapper>
                <LanguageProvider i18n={i18nInstance}>
                  <WebsocketsProvider>
                    <IntercomProviderWrapper>
                      <UiProviderWrapper reactApp={reactApp} fullScreen={fullScreen} themeMode={themeMode}>
                        {children}
                      </UiProviderWrapper>
                    </IntercomProviderWrapper>
                  </WebsocketsProvider>
                </LanguageProvider>
              </LoadingStateProviderWrapper>
            </SentryProviderWrapper>
          </GlobalConfigProvider>
        </QueryClientProvider>
      </RecoilWrapper>
    </AngularAppControllerReadyProvider>
  )
  return withRouter ? <HashRouter>{content}</HashRouter> : content
}

const UiProviderWrapper = ({
  children,
  fullScreen,
  themeMode,
  reactApp
}: {
  children: ReactNode
  fullScreen?: boolean
  themeMode?: 'light' | 'dark'
  reactApp: 'default' | 'legacy'
}) => {
  const focusRingsEnabled = ConfigModel.isFeatureEnabled('keyboard_focus_rings')

  return (
    <UiProvider reactApp={reactApp} fullScreen={fullScreen} themeMode={themeMode} enableFocusRings={focusRingsEnabled}>
      {children}
    </UiProvider>
  )
}

const RecoilWrapper = ({ children, recoilProps }: { children: ReactNode; recoilProps?: RecoilRootProps }) => {
  return (
    <RecoilRoot
      initializeState={
        recoilProps?.override && recoilProps.initializeState
          ? recoilProps.initializeState
          : ({ set }) => {
              // these are set on navigation, but we need to initialize them with the current url
              // in tests this is overwritten since not all tests are written with routing. You can
              // pass in custom overrides as well.
              const { runbookId, runbookVersionId, accountSlug } = parseResourceURLParams(
                document.location.href.split('#')?.[1] ?? ''
              )

              set(accountSlugUrlParamState, accountSlug)
              set(runbookVersionUrlParamState, runbookVersionId)
              set(runbookUrlParamState, runbookId)
            }
      }
    >
      {children}
    </RecoilRoot>
  )
}

const LoadingStateProviderWrapper = ({ children }: { children: ReactNode }) => {
  const reactNavigationEnabled = ConfigModel.isFeatureEnabled('react_workspace')

  return reactNavigationEnabled ? <LoadingStateProvider>{children}</LoadingStateProvider> : <>{children}</>
}

const SentryProviderWrapper = ({ enabled, children }: { enabled: boolean; children: ReactNode }) => {
  const { sentryDsn, version } = ConfigModel.get()

  useEffect(() => {
    if (!enabled || !sentryDsn) return

    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn: sentryDsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          )
        }),
        new Sentry.Replay()
      ],

      // Sentry.Replay configuration
      // Capture Replay for 10% of all sessions,
      replaysSessionSampleRate: 0.1,
      // Plus, capture Replays for 100% of sessions with an error
      replaysOnErrorSampleRate: 1.0,

      // Sentry.BrowserTracing configuration
      // Send all transactions to sentry
      tracesSampleRate: 1.0,
      tracePropagationTargets: [/^https:\/\/.*cutover\.(com|cloud)/]
    })

    Sentry.setTag('version', version.label)
  }, [enabled, sentryDsn])

  return <>{children}</>
}
