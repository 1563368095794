import { format } from 'date-fns'

import { useCurrentUser } from 'main/recoil/data-access'
import { useInstanceName, useUserAnonymization } from '../../services/hooks'
import { ConfigModel } from 'main/data-access'

export const useHeap = () => {
  const { heapAppId, version, enabledFeatures } = ConfigModel.get()
  const { anonUserEmail, anonUserId, anonReferrerId } = useUserAnonymization()
  const currentUser = useCurrentUser()
  const instanceName = useInstanceName()

  const loadHeap = () => {
    if (!heapAppId || window.heap) return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = '/heap_loader.js'
    document.head.appendChild(script)

    window.heap = window.heap || []
    ;(window.heap.appid = heapAppId), (window.heap.config = { secureCookie: true })
    for (
      var n = function (e: any) {
          return function (...args: any[]) {
            window.heap.push([e].concat(args))
          }
        },
        p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty'
        ],
        o = 0;
      o < p.length;
      o++
    ) {
      window.heap[p[o]] = n(p[o])
    }
  }

  const initializeAnonymousAnalytics = () => {
    if (!heapAppId || !window.heap) return

    window.heap.addUserProperties({
      'Instance Name': instanceName
    })
    window.heap.clearEventProperties()
    window.heap.addEventProperties({
      'App Version': version.label,
      'Local Time': new Date().toISOString(),
      'Features Enabled': enabledFeatures.toString()
    })
  }

  const initializeUserAnalytics = () => {
    if (!heapAppId || !window.heap || !currentUser) return

    const now = new Date()
    window.heap.addUserProperties({
      'User Identifier': anonUserId,
      'User Sign-Up Referrer': anonReferrerId,
      'User Roles': currentUser.roles,
      'Is Cutover': currentUser.email.search(/@cutover.com/) !== -1 ?? false,
      'Instance Name': instanceName,
      Email: anonUserEmail
    })
    window.heap.clearEventProperties()
    window.heap.addEventProperties({
      'User Roles': currentUser.roles,
      'Day of week': format(now, 'EEEE'),
      'Week of month': Math.ceil(parseInt(format(now, 'd')) / 7),
      'Week of year': format(now, 'w'),
      'Features Enabled': enabledFeatures.toString()
    })

    window.heap.identify(anonUserId)
  }

  const resetAnalyticsUserIdentity = () => {
    if (!heapAppId || !window.heap) return

    window.heap.resetIdentity()
    window.heap.removeEventProperty('User Roles')
  }

  return {
    loadHeap,
    initializeAnonymousAnalytics,
    initializeUserAnalytics,
    resetAnalyticsUserIdentity
  }
}
