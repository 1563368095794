import { ButtonHTMLAttributes, Fragment, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { Controller, FormProvider, useFormContext } from 'react-hook-form'
import { useMount } from 'react-use'
import styled from 'styled-components/macro'

import {
  Box,
  Checkbox,
  colors,
  Form,
  Icon,
  ListItem,
  Message,
  MultiSelect,
  Select,
  Text,
  TextInput
} from '@cutover/react-ui'
import { IntegrationActionItemFormType } from './integration-action-item-types'
import {
  CustomFieldOptionType,
  IntegrationFormSettingFields
} from '../shared-integration-components/integration-form-setting-fields'
import { ActionConfig, IntegrationSettingOptionsConfig, useLanguage } from 'main/services/hooks'
import { CustomField, IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { useAccountsQuery } from 'main/services/queries/use-accounts'
import { useCustomFieldsQuery } from 'main/services/queries/use-custom-fields'
import { useIntegrationActionItemsQuery } from 'main/services/queries/use-integration-action-item'
import { ConfigModel } from 'main/data-access'

export type IntegrationActionItemFormProps = {
  integrationActionItem?: IntegrationActionItem
  integrationSetting?: IntegrationSetting
  defaultValues?: IntegrationActionItemFormType
  apiError?: string
}

export type CustomFieldMapping = {
  key: string
  value: number
}

type Hash = {
  [key: string]: string
}

export const IntegrationActionItemForm = ({
  integrationActionItem,
  integrationSetting,
  defaultValues,
  apiError
}: IntegrationActionItemFormProps) => {
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'form' })
  const methods = useFormContext<IntegrationActionItemFormType>()
  const formMessageRef = useRef<null | HTMLDivElement>(null)
  const { control, formState, getValues, watch, setValue, reset, register } = methods
  const { errors, isDirty } = formState
  const { integrations, integrationHooks, enabledFeatures } = ConfigModel.get()
  const { data: customFields } = useCustomFieldsQuery()
  const { data: accountsData } = useAccountsQuery()
  const { data: integrationActionItems } = useIntegrationActionItemsQuery()
  const accounts = accountsData?.accounts

  const [selectedAction, setSelectedAction] = useState<ActionConfig | undefined>(undefined)
  const [hookOptions, setHookOptions] = useState<CustomFieldOptionType[]>([])
  const [onHooks, setOnHooks] = useState<CustomFieldOptionType[]>([])
  const [actionItemCustomFieldsList, setActionItemCustomFieldsList] = useState<Array<string> | undefined>(undefined)
  const [customFieldOptions, setCustomFieldOptions] = useState<CustomFieldOptionType[]>([])
  const [tabGroups, setTabGroups] = useState<Hash>({ generalSettings: t('generalSettings') })
  const [tabsInError, setTabsInError] = useState<string[] | undefined>(undefined)
  const [selectedTab, setSelectedTab] = useState(tabGroups.generalSettings)
  const [formErrorMessages, setFormErrorMessages] = useState(apiError ? [apiError] : [])

  const currentIntegration = integrations?.find(integration => {
    if (integrationSetting) {
      return integrationSetting?.integration === integration.klass
    } else if (integrationActionItem) {
      return integrationActionItem?.integration_setting.integration === integration.klass
    }
  })
  const availableActions = currentIntegration?.actions ?? []

  const actionItemOptions =
    availableActions?.map(actionItem => ({
      label: actionItem.name,
      value: actionItem.klass
    })) ?? []

  const accountOptions =
    accounts?.map(account => ({
      label: account.name,
      value: account.id
    })) ?? []
  accountOptions.unshift({
    label: 'Global',
    value: -1
  })

  const handleActionChange = useCallback(
    (value: string) => {
      const { id } = getValues()

      if (value !== defaultValues?.integration_action && id === defaultValues?.id) {
        setValue('on', [])
        setOnHooks([])
      }

      if (value === defaultValues?.integration_action) {
        reset(defaultValues)
        setOnHooks(defaultValues?.on)
      }

      const selected = availableActions?.find(action => value === action.klass)
      setSelectedAction(selected)
      setSettingsDefaultValues(selected?.settings)

      const requiredCustomFields = selected?.requiredCustomFields
      const fieldsList =
        requiredCustomFields && requiredCustomFields.length > 0
          ? requiredCustomFields.map(cf => cf.name as string)
          : undefined
      setActionItemCustomFieldsList(fieldsList)

      const hookOptions: CustomFieldOptionType[] | undefined = []
      selected?.hooks?.forEach(hook => {
        integrationHooks?.find(ih => {
          if (ih.on.indexOf(hook) !== -1) {
            hookOptions.push({
              label: ih.name,
              value: ih.on
            })
          }
        })
      })
      setHookOptions(hookOptions)
    },
    [availableActions]
  )

  const setSettingsDefaultValues = (settings: IntegrationSettingOptionsConfig | undefined) => {
    if (settings) {
      for (const [settingKey, settingValue] of Object.entries(settings)) {
        if (settingValue?.default) {
          setValue(`settings.${settingKey}`, settingValue.default)
        }
      }
    }
  }

  const filterCustomFieldOptions = (selectedAccountId: number | undefined) => {
    const cfOptions = customFields?.flatMap(customField => {
      return isCustomFieldApplicable(customField, selectedAccountId)
        ? [{ label: customField.name, value: customField.id }]
        : []
    })
    setCustomFieldOptions(cfOptions || [])
  }

  const isCustomFieldApplicable = (
    customField: CustomField & { accountId?: number },
    selectedAccountId: number | undefined
  ) => {
    const parsedConstraint = JSON.parse(customField.constraint || '{}')
    if (parsedConstraint?.integration_action_item_id && parsedConstraint?.integration_action_item_id.length > 0) {
      return parsedConstraint.integration_action_item_id.includes(integrationActionItem?.id)
    }

    if (customField.global) {
      return true
    }

    const isEntityGlobal = selectedAccountId === -1
    if (isEntityGlobal || selectedAccountId !== customField.accountId) {
      return false
    }
    return true
  }

  const buildTabGroups = () => {
    const newTabGroups: Hash = Object.assign({}, { generalSettings: t('generalSettings') })
    if (selectedAction) {
      for (const setting in selectedAction.settings) {
        const group = selectedAction.settings[setting].group
        if (group) {
          const settingToCamel = camelizeString(group)
          if (!newTabGroups[settingToCamel]) {
            newTabGroups[settingToCamel] = group
          }
        }
      }
      setTabGroups(newTabGroups)
    }
  }

  const camelizeString = (str: string) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  }

  useEffect(() => {
    const { integration_action } = getValues()
    handleActionChange(integration_action)
  }, [watch('integration_action')])

  useEffect(() => {
    buildTabGroups()
    setSelectedTab(tabGroups.generalSettings)
  }, [selectedAction])

  useEffect(() => {
    if (integrationActionItem) {
      setSettingsDefaultValues(integrationActionItem?.settings as IntegrationSettingOptionsConfig)
      filterCustomFieldOptions(integrationActionItem?.entity?.global ? -1 : integrationActionItem?.entity?.account_id)
      const defaultOnHookValues: CustomFieldOptionType[] = []
      integrationActionItem.on?.forEach(hook => {
        integrationHooks?.find(ih => {
          if (ih.on === hook) {
            defaultOnHookValues.push({
              label: ih.name,
              value: ih.on
            })
          }
        })
      })
      setOnHooks(defaultOnHookValues)
    } else {
      filterCustomFieldOptions(getValues('additional_settings.visibility'))
    }
  }, [customFields, integrationActionItem])

  useEffect(() => {
    filterCustomFieldOptions(getValues('additional_settings.visibility'))
  }, [watch('additional_settings.visibility')])

  useEffect(() => {
    computeTabsInError()
  }, [errors])

  useEffect(() => {
    if (tabsInError && tabsInError.length > 0) {
      setFormErrorMessages(apiError ? [apiError, t('common:formInvalid')] : [t('common:formInvalid')])
      return
    }

    setFormErrorMessages(apiError ? [apiError] : [])
    if (formErrorMessages.length > 0) {
      formMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [tabsInError, apiError])

  useEffect(() => {
    setFormErrorMessages([])
  }, [isDirty])

  useMount(() => {
    setOnHooks(defaultValues?.on || [])
  })

  const selectTab = (event: SyntheticEvent, tab: string) => {
    setSelectedTab(tab)
    event.preventDefault()
  }

  const setActiveTabClassName = (tab: string) => {
    if (selectedTab === tab) {
      return true
    }
  }

  const computeTabsInError = () => {
    setTabsInError(undefined)
    const errorKeys: string[] = []
    const tabs: string[] = []

    if (Boolean(errors.name)) {
      tabs.push('generalSettings'.toLowerCase())
    }

    if (!isEmpty(errors) && errors.settings && selectedAction?.settings) {
      Object.keys(errors.settings).map(error => {
        errorKeys.push(error)
      })

      for (const [key, value] of Object.entries(selectedAction?.settings)) {
        if (errorKeys.includes(key) && value.group && !tabs.includes(value.group.toLowerCase())) {
          tabs.push(value.group.toLowerCase())
        }
      }
    }

    setTabsInError(tabs)
  }

  const isTabInError = (group: string) => {
    return tabsInError?.includes(group.toLowerCase())
  }

  const tabNavigation = () => {
    return (
      <>
        {!isEmpty(selectedAction?.settings) && (
          <Box
            direction="row"
            gap="small"
            css={`
              margin-bottom: 16px;
              min-width: 500px;
            `}
          >
            {Object.keys(tabGroups).map(group => {
              return (
                <StyledTabButton
                  key={group}
                  active={setActiveTabClassName(tabGroups[group])}
                  onClick={e => selectTab(e, tabGroups[group])}
                >
                  {tabGroups[group]}
                  {isTabInError(group) ? (
                    <Icon
                      icon="alert"
                      size="16px"
                      color="alert"
                      css={`
                        margin-left: 4px;
                      `}
                    />
                  ) : null}
                </StyledTabButton>
              )
            })}
          </Box>
        )}
      </>
    )
  }

  return (
    <FormProvider {...methods}>
      <Form>
        {formErrorMessages && formErrorMessages.length > 0 ? (
          <Box
            ref={formMessageRef}
            css={`
              margin-bottom: 16px;
            `}
          >
            <Message message={formErrorMessages} type="error" />
          </Box>
        ) : null}
        <Box direction="column">
          <Controller
            name="integration_action"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                onChange={onChange}
                required
                data-testid="select-integration-action-dropdown"
                inputRef={ref}
                label={t('field.action')}
                value={value}
                options={actionItemOptions}
              />
            )}
          />
          {selectedAction?.usage && (
            <Box margin={{ bottom: '16px' }}>
              <Message message={selectedAction.usage} />
            </Box>
          )}
          {tabNavigation()}
          {selectedTab === tabGroups.generalSettings && (
            <>
              {selectedAction && (
                <>
                  <TextInput label={t('field.name')} {...register('name')} required hasError={Boolean(errors?.name)} />
                  <TextInput
                    label={t('field.imageUrl')}
                    hasError={Boolean(errors['image_url'])}
                    inlineError={errors['image_url']?.message}
                    {...register('image_url')}
                  />
                  {Boolean(integrationActionItem?.id) ? (
                    <TextInput
                      label={t('field.trigger')}
                      disabled
                      value={onHooks.map(option => option.label).join(', ')}
                    />
                  ) : (
                    <Controller
                      name="on"
                      control={control}
                      render={({ field: { onChange, ref } }) => (
                        <MultiSelect
                          onChange={options => {
                            onChange(options)
                            setOnHooks(options || [])
                          }}
                          required
                          disabled={Boolean(integrationActionItem?.id)}
                          hasError={Boolean(errors['on'])}
                          inputRef={ref}
                          label={t('field.trigger')}
                          value={onHooks || []}
                          options={hookOptions}
                          renderOption={(opt, { selected, highlighted, onDeselect }) => {
                            return (
                              <ListItem
                                size="small"
                                onClickRemove={
                                  onDeselect && !Boolean(integrationActionItem?.id) ? () => onDeselect(opt) : undefined
                                }
                                active={highlighted || selected}
                                title={opt.label}
                              />
                            )
                          }}
                        />
                      )}
                    />
                  )}
                  <Controller
                    name={'additional_settings.visibility'}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        onChange={onChange}
                        required
                        disabled={Boolean(integrationActionItem?.id)}
                        hasError={Boolean(errors['on'])}
                        inputRef={ref}
                        label={t('field.additionalSettings.visibility')}
                        value={value}
                        options={accountOptions}
                      />
                    )}
                  />
                  <Box>
                    {/* Todo: Should use checkbox group component */}
                    <Text size="13px" color="text-light">
                      {t('additionalSettings')}
                    </Text>
                    <Checkbox
                      defaultChecked={Boolean(getValues('additional_settings.auto_start'))}
                      {...register('additional_settings.auto_start')}
                      label={t('field.additionalSettings.autoStart')}
                      helpText={t('field.additionalSettings.autoStartTooltip')}
                    />
                    {enabledFeatures.includes('allow_integrations_enable_start_fixed') && (
                      <Checkbox
                        defaultChecked={Boolean(getValues('additional_settings.enable_start_fixed'))}
                        {...register('additional_settings.enable_start_fixed')}
                        label={t('field.additionalSettings.enableStartFixed')}
                        helpText={t('field.additionalSettings.enableStartFixedTooltip')}
                      />
                    )}
                    <Checkbox
                      defaultChecked={Boolean(getValues('options.cancellable'))}
                      {...register('options.cancellable')}
                      label={t('field.optionOverrides.cancellable.label')}
                      helpText={t('field.optionOverrides.cancellable.tooltip')}
                    />
                    <Checkbox
                      defaultChecked={Boolean(getValues('options.notify_on_cancel'))}
                      {...register('options.notify_on_cancel')}
                      label={t('field.optionOverrides.notifyOnCancel.label')}
                      helpText={t('field.optionOverrides.notifyOnCancel.tooltip')}
                    />
                    <Checkbox
                      defaultChecked={Boolean(getValues('options.finish_task_on_success'))}
                      {...register('options.finish_task_on_success')}
                      label={t('field.optionOverrides.finishTaskOnSuccess.label')}
                      helpText={t('field.optionOverrides.finishTaskOnSuccess.tooltip')}
                    />
                    <Checkbox
                      defaultChecked={Boolean(getValues('options.include_context_in_request'))}
                      {...register('options.include_context_in_request')}
                      label={t('field.optionOverrides.includeContextInRequest.label')}
                      helpText={t('field.optionOverrides.includeContextInRequest.tooltip')}
                    />
                    <Checkbox
                      defaultChecked={Boolean(getValues('options.execute_in_rehearsal'))}
                      {...register('options.execute_in_rehearsal')}
                      label={t('field.optionOverrides.executeInRehearsal.label')}
                      helpText={t('field.optionOverrides.executeInRehearsal.tooltip')}
                    />
                  </Box>
                </>
              )}
              {actionItemCustomFieldsList && (
                <Box direction="column" margin={{ top: '16px' }}>
                  <Text size="13px" color="text-light">
                    {t('actionCustomFields')}
                  </Text>
                  {actionItemCustomFieldsList.map(customField => {
                    return <Text key={customField}>{customField}</Text>
                  })}
                </Box>
              )}
            </>
          )}

          {Object.keys(tabGroups).map(group => {
            return (
              <Fragment key={group}>
                {selectedTab !== tabGroups.generalSettings && selectedTab === tabGroups[group] && (
                  <IntegrationFormSettingFields
                    settings={selectedAction?.settings}
                    customFieldOptions={customFieldOptions}
                    group={tabGroups[group]}
                    actionItems={integrationActionItems}
                  />
                )}
              </Fragment>
            )
          })}
        </Box>
      </Form>
    </FormProvider>
  )
}

// TODO: need to use tabs from react ui
const StyledTabButton = styled.button<ButtonHTMLAttributes<HTMLButtonElement> & { active?: boolean }>`
  font-weight: 400;
  color: ${colors.textLight};
  line-height: 40px;
  border-bottom: 4px solid transparent;
  ${props => props.active && `border-bottom: 4px solid #2A55C3; font-weight: 600; color: ${colors.primary};`}
`
