import { useMemo } from 'react'

import { Box, Button, FavoriteStar, Pill } from '@cutover/react-ui'
import { RunbookSettingsMenu } from './runbook-settings-menu'
import { ScheduledStartRunbookModal } from '../modals/scheduled-start-runbook/scheduled-start-runbook-modal'
import { useToggleRecoilFavorites } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { CsvImportModal } from '../modals/csv-import/csv-import-modal'
import { MakeTemplateModal } from '../modals/make-template/make-template-modal'
import { RunbookDuplicateModalWrapper } from '../modals/duplicate/runbook-duplicate-modal-wrapper'
import {
  ActiveAccountModel,
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CurrentRunbookVersionModel,
  RunbookTypeModel,
  RunbookViewModel
} from 'main/data-access'
import { ModalActiveType } from 'main/recoil/runbook'

export const RunbookToolbar = () => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })

  const slug = ActiveAccountModel.get('slug')

  const { active: activeModal } = RunbookViewModel.get('modal')
  const handleCloseModal = RunbookViewModel.onAction('modal:close')
  const onOpenModal = RunbookViewModel.onAction('modal:open')

  const { start_scheduled: startScheduled } = CurrentRunbookVersionModel.get()
  const run = ActiveRunModel.get()
  const hasScheduledStart = !!startScheduled

  const runbookVersionId = ActiveRunbookVersionModel.get('id')
  const { id: runbookId, archived, is_template, name, template_type: templateType } = ActiveRunbookModel.get()

  const [favorite, setFavorite] = useToggleRecoilFavorites({ id: runbookId })
  const isFavorite = !!favorite

  const toggleFavorite = () => {
    if (runbookId) {
      setFavorite(
        favorite || {
          account_slug: slug,
          archived,
          is_template,
          name,
          template_type: templateType,
          id: runbookId
        }
      )
    }
  }

  const handleOpenModal = (type: ModalActiveType['type']) => {
    onOpenModal({ type } as ModalActiveType)
  }

  return (
    <>
      <RunbookTitleLabel
        hasScheduledStart={hasScheduledStart}
        onClickSchedule={() => handleOpenModal('runbook-schedule')}
      />
      <Box direction="row" css="margin-top: 1px;">
        <RunbookSettingsMenu onClickOption={handleOpenModal} />
        <FavoriteStar
          isFavorite={isFavorite}
          toggleFavorite={toggleFavorite}
          tooltip={{
            addFavorite: t('addFavorite'),
            removeFavorite: t('removeFavorite'),
            placement: 'bottom'
          }}
        />
      </Box>
      {activeModal?.type === 'runbook-schedule' && (
        <ScheduledStartRunbookModal startScheduled={startScheduled} onClose={handleCloseModal} />
      )}
      {activeModal?.type === 'tasks-csv-import' && (
        <CsvImportModal
          open
          closeModal={handleCloseModal}
          planningMode={!['live', 'rehearsal'].includes(run?.run_type || '')}
          resourceType={templateType === 'snippet' ? 'Snippet' : 'Runbook'}
        />
      )}
      {activeModal?.type === 'runbook-make-template' && (
        <MakeTemplateModal onClose={handleCloseModal} runbookId={runbookId} runbookVersionId={runbookVersionId} />
      )}
      {activeModal?.type === 'runbook-duplicate' && <RunbookDuplicateModalWrapper closeModal={handleCloseModal} />}
    </>
  )
}

const RunbookTitleLabel = ({
  onClickSchedule,
  hasScheduledStart
}: {
  onClickSchedule: () => void
  hasScheduledStart: boolean
}) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'runbook' })
  const { template_version: templateVersion } = ActiveRunbookVersionModel.get()
  const run = ActiveRunModel.get()

  const runType = run?.run_type
  const canUpdateRunbook = ActiveRunbookModel.can('update')
  const runbookType = RunbookTypeModel.getFrom('active-runbook')

  const {
    template_type: templateType,
    template_status: templateStatus,
    restricted: sensitiveRunbook
  } = ActiveRunbookModel.get()

  const pillProps = useMemo(() => {
    // incident runbook
    if (runbookType?.incident) {
      return sensitiveRunbook
        ? { label: t('sensitiveIncident'), color: 'primary' }
        : { label: t('incident'), color: 'primary' }
    }

    // has run type
    if (!!runType) {
      if (runType === 'live') {
        return runbookType.dynamic
          ? { label: t('liveRunDynamic'), color: 'primary' }
          : { label: t('liveRun'), color: 'primary' }
      } else if (runType === 'rehearsal') {
        return runbookType.dynamic
          ? { label: t('rehearsalDynamic'), color: 'secondary' }
          : { label: t('rehearsal'), color: 'secondary' }
      }
    }

    // template type
    if (templateType === 'snippet') {
      return { label: t('status.snippet'), color: 'secondary' }
    } else if (templateType === 'default') {
      return templateStatus === 'template_approved'
        ? { label: t('status.approved', { version: templateVersion }), color: 'success' }
        : templateStatus === 'template_rejected'
        ? { label: t('status.rejected', { version: templateVersion }), color: 'error' }
        : { label: t('status.draft', { version: templateVersion }), color: 'secondary' }
    }

    return null
  }, [templateStatus, templateType, runbookType, runType, sensitiveRunbook, t])

  return pillProps ? (
    <Pill
      {...pillProps}
      data-testid={'runbook-toolbar-pill-' + pillProps.label}
      css={`
        position: relative;
        top: 6px;
        min-width: fit-content;
        cursor: default;
      `}
    />
  ) : !hasScheduledStart && canUpdateRunbook ? (
    <Button
      primary
      label={t('schedule')}
      onClick={onClickSchedule}
      size="small"
      css={`
        margin-left: 4px;
        top: 1px;
      `}
    />
  ) : null
}
