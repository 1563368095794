import { memo, useCallback, useMemo, useState } from 'react'
import { eventManager } from 'event-manager'
import { Heading } from 'grommet'
import { unescape } from 'lodash'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { Box, BrandIcon, Button, IconName, MenuListItemProps, Pill, TabGroup, TabItem } from '@cutover/react-ui'
import { useToggleFilterPanel } from 'main/components/layout/layout-hooks'
import { useHasTemplatesAwaitingReview } from 'main/components/shared/hooks/user'
import { CreateSnippetModal } from 'main/components/workspace/modals/create-snippet/create-snippet-modal'
import { useAccountDashboards, useAccountViewPermissions } from 'main/services/api/data-providers/account/account-data'
import { useLanguage } from 'main/services/hooks'
import { Account } from 'main/services/queries/types'
import { usePermissions } from 'main/services/queries/use-permissions'
import { ConfigModel } from 'main/data-access'

type AccountActiveTab = 'dashboards' | 'runbooks' | 'templates' | 'snippets' | 'central-teams' | 'events'
type HeaderAccount = Pick<Account, 'id' | 'slug' | 'name' | 'icon_name' | 'account_type_name'>

// TODO: When we do the runbooks page, extract out a common component for the page header
export const WorkspaceHeader = memo(({ account }: { account: HeaderAccount }) => {
  const { t } = useLanguage('pageHeader', { keyPrefix: 'workspace' })
  const [isOpenCreateSnippetModal, setIsOpenCreateSnippetModal] = useState(false)
  const hasTemplatesToReview = useHasTemplatesAwaitingReview()

  const isEventsPageMatch = !!useMatch({ path: '/app/:accountSlug/events', end: false })
  const isCentralTeamsPageMatch = !!useMatch('/app/:accountSlug/settings/teams')
  const runbooksPageMatch = useMatch({ path: '/app/:accountSlug/runbooks/:page', end: false })
  const runbooksPage = runbooksPageMatch?.params.page
  const [searchParams] = useSearchParams()
  const runbooktypePage = searchParams.get('template_type')

  const permissions = usePermissions('runbooks')
  const canCreateRunbooks = permissions('create')
  const canCreateSnippets = permissions('create_snippet')
  const showCreateButton = runbooktypePage === 'snippet' ? canCreateSnippets : canCreateRunbooks

  const activeTab: AccountActiveTab = useMemo(() => {
    if (isCentralTeamsPageMatch) {
      return 'central-teams'
    } else if (runbooksPage == 'dashboard') {
      return 'dashboards'
    } else if (isEventsPageMatch) {
      return 'events'
    } else {
      if (runbooktypePage === 'snippet') {
        return 'snippets'
      } else if (runbooktypePage === 'default') {
        return 'templates'
      } else {
        return 'runbooks'
      }
    }
  }, [runbooksPage, isCentralTeamsPageMatch, isEventsPageMatch, runbooktypePage])

  const openCreateRunbookModal = () => {
    eventManager.emit('open-create-runbook-modal')
  }

  const openCreateSnippetModal = () => {
    setIsOpenCreateSnippetModal(true)
  }

  return (
    <Box direction="row" fill justify="between" align="center" gap="16px">
      <Box direction="row" fill align="center" gap="6px" pad={{ left: '2px' }}>
        <Box height={{ min: '40px' }} width={{ min: '40px' }}>
          {<BrandIcon icon={account.icon_name} size="40px" color="primary" />}
        </Box>
        <Box justify="between" fill>
          <WorkspaceTitle responsive={false} margin={{ left: 'xsmall', right: 'none', vertical: 'none' }} level="1">
            {unescape(account.name)}
            {account.account_type_name && account.account_type_name !== 'Custom' && (
              <Box css="display: inline-block; vertical-align: 2px;" pad={{ left: '6px' }}>
                <Pill color="primary" label={account.account_type_name} size="medium" />
              </Box>
            )}
          </WorkspaceTitle>

          <Box
            css={`
              white-space: nowrap;
              overflow-y: hidden;
              overflow-x: auto;
            `}
          >
            <Tabs
              activeTab={activeTab}
              accountSlug={account.slug}
              accountId={account.id}
              hasTemplatesToReview={hasTemplatesToReview}
            />
          </Box>
        </Box>
      </Box>
      {!isCentralTeamsPageMatch && runbooktypePage !== 'default' && showCreateButton && (
        <>
          <Button
            primary
            label={t('createButton', { context: runbooktypePage === 'snippet' ? 'snippet' : 'runbook' })}
            onClick={runbooktypePage === 'snippet' ? openCreateSnippetModal : openCreateRunbookModal}
            icon="add"
            data-testid="create-button-header"
          />
          <CreateSnippetModal
            accountId={account.id}
            isModalOpen={isOpenCreateSnippetModal}
            setIsModalOpen={setIsOpenCreateSnippetModal}
          />
        </>
      )}
    </Box>
  )
})

const Tabs = memo(
  ({
    activeTab,
    accountSlug,
    accountId,
    hasTemplatesToReview
  }: {
    activeTab: AccountActiveTab
    accountSlug: string
    accountId: number
    hasTemplatesToReview?: boolean
  }) => {
    const { t } = useLanguage('pageHeader', { keyPrefix: 'workspace.tabs' })
    const { eventsEnabled } = ConfigModel.get()
    const navigate = useNavigate()
    const toggleFilterPanel = useToggleFilterPanel()
    const { viewPermissions } = useAccountViewPermissions()
    const canViewCentralTeamsTab = viewPermissions?.teams
    const [searchParams] = useSearchParams()
    const { dashboards: allDashboards } = useAccountDashboards()
    const dashboards = allDashboards?.filter(d => d.context === 'Account')
    const activeDashboardMatch = useMatch(`/app/${accountSlug}/runbooks/dashboard/:dashboardId`)
    const activeDashboard = dashboards?.find(d => String(d.id) === activeDashboardMatch?.params.dashboardId)
    const defaultDashboard = getDefaultDashboard({ accountSlug, dashboards })

    // Retain a set of search params across pages.
    // Would be better to work with immutable objects here but this is how searchParams seem to work for React Router?
    if (!searchParams.has('sort_by')) searchParams.append('sort_by', 'updated_at')
    if (!searchParams.has('sort_dir')) searchParams.append('sort_dir', 'desc')
    if (searchParams.has('template_type')) searchParams.delete('template_type')
    const queryString = `?${searchParams.toString()}`

    const dashboardItems: MenuListItemProps[] | undefined = useMemo(
      () =>
        dashboards?.map(dashboard => ({
          label: dashboard.name,
          onClick: () => {
            navigate(
              `/app/${accountSlug}/runbooks/dashboard/${dashboard.id}${
                dashboard?.key !== 'analytics' && activeTab === 'runbooks' ? queryString : ''
              }`
            )
          },
          selected: activeDashboard?.id === dashboard.id
        })),
      [dashboards, activeDashboard, accountSlug, queryString]
    )

    const handleClickCentralTeams = useCallback(() => {
      toggleFilterPanel(false)
      navigate(`/app/${accountSlug}/settings/teams`)
    }, [accountSlug])

    const items: TabItem[] = useMemo(() => {
      return [
        {
          label: defaultDashboard ? defaultDashboard.name : t('dashboards'),
          icon: 'dashboard' as IconName,
          active: activeTab === 'dashboards',
          menuItems: dashboardItems,
          maxWidth: 120,
          linkTo: defaultDashboard
            ? `/app/${accountSlug}/runbooks/dashboard/${defaultDashboard.id}${
                defaultDashboard?.key !== 'analytics' && activeTab === 'runbooks' ? queryString : ''
              }`
            : undefined,
          'aria-controls': 'tabpanel-dashboards',
          id: 'tab-dashboards'
        },
        {
          label: t('runbooks'),
          icon: 'runbook' as IconName,
          active: activeTab === 'runbooks',
          linkTo: `/app/${accountSlug}/runbooks/list${
            !!activeDashboardMatch ? queryString : '?sort_by=updated_at&sort_dir=desc'
          }`,
          'aria-controls': 'tabpanel-runbooks',
          id: 'tab-runbooks'
        },
        {
          label: t('templates'),
          icon: 'template' as IconName,
          active: activeTab === 'templates',
          badge: hasTemplatesToReview ? { type: 'primary' as const, overlap: false } : undefined,
          linkTo: `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=default`,
          'aria-controls': 'tabpanel-runbooks',
          id: 'tab-templates'
        },
        {
          label: t('snippets'),
          icon: 'snippet' as IconName,
          active: activeTab === 'snippets',
          linkTo: `/app/${accountSlug}/runbooks/list?sort_by=updated_at&sort_dir=desc&template_type=snippet`,
          'aria-controls': 'tabpanel-runbooks',
          id: 'tab-snippets'
        },
        ...(eventsEnabled
          ? [
              {
                label: t('events'),
                icon: 'calendar' as IconName,
                active: activeTab === 'events',
                linkTo: `/app/${accountSlug}/events/timeline?aid=${accountId}`,
                'aria-controls': 'tabpanel-events',
                id: 'tab-events'
              }
            ]
          : []),
        ...(canViewCentralTeamsTab
          ? [
              {
                label: t('teams'),
                icon: 'users' as IconName,
                active: activeTab === 'central-teams',
                linkTo: `/app/${accountSlug}/settings/teams`,
                onClick: handleClickCentralTeams,
                'aria-controls': 'tabpanel-central-teams',
                id: 'tab-central-teams'
              }
            ]
          : [])
      ]
    }, [
      eventsEnabled,
      activeDashboardMatch,
      activeTab,
      dashboardItems,
      handleClickCentralTeams,
      queryString,
      hasTemplatesToReview
    ])

    return <TabGroup items={items} />
  }
)

const getDefaultDashboard = ({ accountSlug, dashboards }: { accountSlug: string; dashboards?: any[] }) => {
  const existingSettings = localStorage.getItem('last_viewed_workspace_dashboard')
  const parsedExistingSettings = existingSettings ? JSON.parse(existingSettings) : {}
  let defaultDashboard
  if (parsedExistingSettings.hasOwnProperty(accountSlug)) {
    // If we have a last visited dash for this workspace, and it exists, use it
    const defaultDashboardId = parsedExistingSettings[accountSlug]
    defaultDashboard = dashboards?.find(d => Number(defaultDashboardId) === d.id)
  } else {
    defaultDashboard = dashboards?.find(d => d.key !== 'analytics')
  }
  return defaultDashboard ? defaultDashboard : dashboards?.[0]
}

const WorkspaceTitle = styled(Heading)`
  font-size: 20px;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  top: 9px;
`
