import { memo, MouseEventHandler, Ref, SyntheticEvent, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import {
  Avatar,
  AvatarGroup,
  AvatarSubject,
  Box,
  Checkbox,
  DependencyType,
  duration as durationFormatter,
  Icon,
  IconButton,
  IconName,
  Pill,
  SpinnerIcon,
  TaskItemIcon,
  TaskItemIconProps,
  TaskItemLayout,
  Text,
  TextProps,
  Tooltip,
  useControlledMenuTriggerRef
} from '@cutover/react-ui'

export type IntegrationStatusProps = {
  status: string
  color: string
  error: string
}

export type TaskItemProps = {
  isOptionsMenuOpen: boolean
  avatars?:
    | { tooltip: string | boolean; subject?: AvatarSubject }
    | { tooltip: string | boolean; subject: AvatarSubject }[]
  selected?: boolean
  showSkip?: boolean
  persistCommentsButton?: boolean
  criticalPathIndicator?: { border: 'dashed' | 'solid'; tooltip: string }
  customFieldPillLabels?: { label: string; color?: string }[]
  dateText?: string
  disabled?: boolean
  draggable?: boolean
  durationDiff?: {
    text: string
    color: string
  }
  durationText?: string
  editable?: boolean
  error?: string
  highlight?: boolean
  iconProps: TaskItemIconProps
  integrationImageUrl?: string
  internalId: number
  isLate?: boolean
  isLateStartFixed?: boolean
  isLoadingTeams?: boolean
  name: string
  onChangeSelect: (e: SyntheticEvent) => void
  onClickAvatar: (e: SyntheticEvent, subject?: AvatarSubject) => void
  onClickComments: MouseEventHandler
  onClickError: () => void
  onClickOptions: (triggerRef: Ref<HTMLElement>) => void
  onClickDependencies: (triggerRef: Ref<HTMLElement>, type: DependencyType) => void
  onClickSkip: MouseEventHandler
  onFocus?: () => void
  onClick: () => void
  onDrop: (e: React.DragEvent) => void
  showBorderTop?: boolean
  showAvatarsAsPill?: boolean
  startDiff?: number
  streamName: string
  nameBold?: boolean
  namePrefixIcon?: IconName
  updating?: boolean
  /** The label for the select checkbox. All props matching ^label* have defaults but are not currently translated. */
  labelSelectCheckbox?: string
  /** The label for the skip button. All props matching ^label* have defaults but are not currently translated. */
  labelSkipButton?: string
  /** The label for the menu button. All props matching ^label* have defaults but are not currently translated. */
  labelMenuButton?: string
  /** The label for the comments button. All props matching ^label* have defaults but are not currently translated. */
  labelCommentsButton?: string
  /** The label for the started text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelStarted?: string
  /** The label for the late text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelLate?: string
  /** The label for the early text that sits underneath the task name. All props matching ^label* have defaults but are not currently translated. */
  labelEarly?: string
  integrationStatusProps?: IntegrationStatusProps
  /** When in highlight mode, this applies to an item that is not included in the filtered tasks array */
  isFaded?: boolean
}

export const TaskItem = memo(
  ({
    isOptionsMenuOpen,
    avatars,
    criticalPathIndicator,
    customFieldPillLabels = [],
    dateText,
    disabled,
    draggable,
    durationDiff,
    durationText,
    editable,
    error,
    highlight,
    iconProps,
    integrationImageUrl,
    internalId,
    isLate,
    isLateStartFixed,
    isLoadingTeams,
    name,
    nameBold,
    namePrefixIcon,
    onChangeSelect,
    onClickAvatar,
    onClickComments,
    onClickError,
    onClickOptions,
    onClickDependencies,
    onClickSkip,
    onDrop,
    onFocus,
    onClick,
    persistCommentsButton,
    selected,
    showBorderTop,
    showSkip,
    showAvatarsAsPill,
    startDiff,
    streamName,
    updating,
    labelCommentsButton = 'Comments',
    labelEarly = 'early',
    labelLate = 'late',
    labelMenuButton = 'More options',
    labelSelectCheckbox = 'Select task',
    labelSkipButton = 'Skip',
    labelStarted = 'Started',
    integrationStatusProps,
    isFaded
  }: TaskItemProps) => {
    const optionsMenuRef = useControlledMenuTriggerRef<HTMLButtonElement>()
    const dependenciesMenuRef = useControlledMenuTriggerRef<HTMLButtonElement>()
    const [isDraggedOver, setIsDraggedOver] = useState(false)

    const handleDragOver = (e: React.DragEvent) => {
      e.preventDefault()
      setIsDraggedOver(true)
    }

    const handleDragEnter = (e: React.DragEvent) => {
      e.preventDefault()
    }

    const handleDragLeave = () => {
      setIsDraggedOver(false)
    }

    const handleDrop = (e: React.DragEvent) => {
      onDrop?.(e)
      setIsDraggedOver(false)
    }

    return (
      <TaskItemLayout
        data-testid="task-list-item"
        editable={editable}
        disabled={disabled || isFaded}
        draggable={draggable}
        highlight={selected || highlight}
        loading={updating}
        isDraggedOver={isDraggedOver}
        onClick={onClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onFocus={onFocus}
        showBorderTop={showBorderTop}
        prefixContent={
          <Box
            flex={false}
            width="30px"
            pad={{ left: '8px' }}
            className={selected ? undefined : 'task-item-display-on-task-hover'}
          >
            <Checkbox
              name={labelSelectCheckbox}
              aria-label={`${labelSelectCheckbox} ${name}`}
              onChange={onChangeSelect}
              checked={selected}
              onClick={e => {
                if (e.clientX !== 0 && e.clientY !== 0) e.currentTarget.blur()
                e.stopPropagation()
              }}
              fill
            />
          </Box>
        }
        dateContent={
          <DateText color={isLateStartFixed ? 'warning' : 'text-light'} $loading={updating}>
            {typeof isLateStartFixed === 'boolean' && <FixedStartText late={isLateStartFixed}>F</FixedStartText>}
            {dateText}
          </DateText>
        }
        icon={
          <TaskItemIcon
            ref={dependenciesMenuRef}
            {...iconProps}
            onClickDependency={type => onClickDependencies(dependenciesMenuRef, type)}
          />
        }
        mainContentPrefix={
          namePrefixIcon ? (
            <Icon icon={namePrefixIcon} color="text-light" />
          ) : integrationImageUrl ? (
            <TaskListIntegrationImage src={integrationImageUrl} />
          ) : null
        }
        mainContent={
          <Text
            truncate="tip"
            tip={name}
            tipPlacement="top"
            weight={nameBold ? 'bold' : 'normal'}
            data-testid="task-item-title"
          >
            <Text color="text-light">#{internalId}</Text>{' '}
            <Text color={isLate ? 'warning' : undefined} css="padding-right: 4px;">
              {name}
            </Text>
            <>
              {customFieldPillLabels.map(item => (
                <Pill
                  {...item}
                  key={`${item.label}-${item.color}`}
                  css="margin-left: 4px;"
                  data-testid={`custom-field-pill-${item.label}`}
                />
              ))}
            </>
          </Text>
        }
        mainContentSubLine={
          <Text size="xsmall" color="text-light" truncate className="task-item-display-on-task-hover">
            {streamName}
            {typeof startDiff === 'number' && (
              <>
                <>&nbsp;•&nbsp;</> {labelStarted}{' '}
                <Text size="xsmall" color={startDiff > 0 ? 'warning' : 'success'} data-testid="task-start-diff-text">
                  {durationFormatter(Math.abs(startDiff))}
                </Text>{' '}
                {startDiff > 0 ? labelLate : labelEarly}
              </>
            )}
          </Text>
        }
        integrationStatus={integrationStatusProps ? <IntegrationStatus {...integrationStatusProps} /> : undefined}
        hasIntegrationError={!!integrationStatusProps?.error}
        endContent={
          <>
            <Box direction="row" align="center" css="flex: 0 0 auto;">
              <Box className={persistCommentsButton ? undefined : 'task-item-display-on-task-hover'}>
                <IconButton
                  data-testid="comment-task-button"
                  icon="message"
                  label={labelCommentsButton}
                  onClick={onClickComments}
                  tipPlacement="top"
                />
              </Box>
              {showSkip && (
                <Box className="task-item-display-on-task-hover">
                  <IconButton
                    data-testid="skip-task-button"
                    disableTooltip
                    icon="skip"
                    label={labelSkipButton}
                    onClick={onClickSkip}
                  />
                </Box>
              )}

              <Box className={!isOptionsMenuOpen ? 'task-item-display-on-task-hover' : ''}>
                <IconButton
                  ref={optionsMenuRef as Ref<HTMLButtonElement> | undefined}
                  data-testid="options-task-button"
                  disableTooltip
                  icon="more-vertical"
                  label={labelMenuButton}
                  isActive={isOptionsMenuOpen}
                  onClick={e => {
                    e.stopPropagation()
                    onClickOptions(optionsMenuRef)
                  }}
                />
              </Box>

              {error && <IconButton label={error} tipPlacement="top" icon="alert" onClick={onClickError} />}
            </Box>
            {criticalPathIndicator && (
              <Box
                flex={false}
                css="position: relative;"
                alignSelf="center"
                height="19px"
                width="19px"
                border={{ size: '1px', color: 'error', style: criticalPathIndicator.border }}
                round
                data-testid="critical-path-indicator"
              >
                <Tooltip content={criticalPathIndicator.tooltip} placement="top">
                  <Text
                    color="error"
                    size="13px"
                    alignSelf="center"
                    css="display: inline-block; width:17px; height:17px; text-align: center; vertical-align: text-top;"
                  >
                    C
                  </Text>
                </Tooltip>
              </Box>
            )}
            {!integrationStatusProps && (
              <Box data-testid="task-duration" flex={false} alignSelf="center" direction="row">
                {durationText && (
                  <Text color="text-light" css="white-space: pre;">
                    {durationText}
                  </Text>
                )}
                {durationDiff ? (
                  <Text color={durationDiff.color} css="white-space: pre;">
                    {' ' + durationDiff.text}
                  </Text>
                ) : null}
              </Box>
            )}
            {showAvatarsAsPill && avatars && (
              <Box css="max-width: 150px;">
                <Pill
                  truncate="tip"
                  label={Array.isArray(avatars) ? avatars[0].subject.name ?? '' : avatars.subject?.name ?? ''}
                  color={
                    Array.isArray(avatars) ? avatars[0].subject.color ?? undefined : avatars.subject?.color ?? undefined
                  }
                  data-testid="task-item-user-team-label"
                />
              </Box>
            )}
          </>
        }
        suffixContent={
          isLoadingTeams ? (
            <SpinnerIcon color="text-light" css="opacity: 0.5;" />
          ) : Array.isArray(avatars) ? (
            <AvatarGroup avatars={avatars} onClickAvatarItem={onClickAvatar} rightPosition={28} />
          ) : (
            <Avatar
              subject={avatars?.subject}
              tooltipPlacement="top"
              tooltip={avatars?.tooltip}
              onClick={onClickAvatar}
            />
          )
        }
      />
    )
  }
)

const IntegrationStatus = ({ status, color, error }: IntegrationStatusProps) => {
  return (
    <Text
      truncate="tip"
      tipPlacement="top"
      color={color}
      css={`
        white-space: nowrap;
        margin-left: auto;
      `}
    >
      {status} {error && <>({error})</>}
    </Text>
  )
}

const flashUpdating = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
`
const FixedStartText = styled(Text)<TextProps & { late: boolean }>`
  font-weight: bold;
  margin-left: 7px;
  padding-right: 6px;
  align-self: center;
  color: ${({ late }) => (late ? 'warning' : 'black')};
`
const DateText = styled(Text)<TextProps & { $loading?: boolean }>`
  white-space: nowrap;
  ${({ $loading }) =>
    $loading &&
    css`
      animation: 0.6s linear infinite ${flashUpdating};
    `}
`

const TaskListIntegrationImage = styled.img`
  border-radius: 50%;
  width: 24px;
  height: 24px;
`
