import { GlobalConfigType } from 'main/services/hooks'
import { useGetConfig, useIsFeatureEnabled } from '../hooks'

// Config model is its own type because it doesn't really fit as a 'model' in a ORM/strict sense. It's more
// of a collection of values used for reference (argument here that all models are just a set of values for
// reference..). But it doesn't have any actions, have an id, or need callbacks.
type ConfigModelType = {
  get: () => GlobalConfigType
  isFeatureEnabled: (key: GlobalConfigType['enabledFeatures'][number]) => boolean
}

export const ConfigModel: ConfigModelType = {
  get: useGetConfig,
  isFeatureEnabled: useIsFeatureEnabled
}
