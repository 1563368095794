import { useCallback, useEffect, useMemo } from 'react'
import { getUnixTime } from 'date-fns'
import { eventManager } from 'event-manager'
import { maxBy, sortBy, uniqBy } from 'lodash'
import { useSearchParams } from 'react-router-dom'

import { addToDate, subtractFromDate } from '@cutover/react-ui'
import { addCustomFieldsFilterData, getCustomFieldsAxisDef } from './custom-fields-utils'
import { RunbooksTimeline } from './runbooks-timeline'
import { AxisDefName } from './types'
import { PagedTimelineRunbooks, useTimelineRunbooksQuery } from './use-runbooks-timeline-query'
import { useTimeline } from './use-timeline'
import { useUpdateAngularRunbooksMeta } from './use-update-angular-runbooks-meta'
import { useFilters } from 'main/components/shared/filter/filter-provider'
import { useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { useWorkspaceMeta } from 'main/services/api/data-providers/workspace'
import { CustomField } from 'main/services/queries/types/custom-fields'
import { ConfigModel } from 'main/data-access'

/**
 * Data loading and initializing container component for runbook timeline
 */

export const RunbooksTimelineContainer = ({
  grouping,
  spotlight,
  customFieldFilters = []
}: {
  grouping?: AxisDefName | undefined
  spotlight?: AxisDefName | undefined
  customFieldFilters?: CustomField[]
}) => {
  // Better to not have to know this angular set class string in the   This is
  // a unique case due to needing to know where to append the canvas element that the
  // (legacy) timeline controller code creates.

  // TODO: Render the canvas element in the react render function and pass its dom ref to
  // the timeline controller for d3 to initialize.

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mountEl = document.querySelector('.tasks-main')! || document.querySelector('.react-main')!
  const [searchParams] = useSearchParams()
  const search = searchParams.toString()
  const accountId = getAccountIdFromUrl()
  const now = useMemo(() => getUnixTime(new Date()), [])
  const initialViewStartDate = subtractFromDate(now, { days: 7 })
  const initialViewEndDate = addToDate(now, { days: 28 })
  const { updateTimeline, initTimeline, timelineControllerRef } = useTimeline({ mountEl })
  const handleUpdateAngularRunbooksMeta = useUpdateAngularRunbooksMeta()
  const startsAfter = useMemo(() => {
    return timelineControllerRef?.current?.viewport.currentStart || initialViewStartDate
  }, [search])
  const { filters } = useFilters()
  const { customFields } = useAccountCustomFields()
  const { runbooksMetaFull } = useWorkspaceMeta()
  const isReactWorkspaceEnabled = ConfigModel.isFeatureEnabled('react_workspace')
  const customFieldIds = runbooksMetaFull?.custom_field_ids

  const filteredCustomFields =
    customFields?.filter(
      cf =>
        cf.display_search &&
        ['runbook_add_edit', 'runbook_edit'].includes(cf.apply_to.slug) &&
        !cf.archived &&
        customFieldIds?.includes(cf.id)
    ) || []

  const customFieldsData = isReactWorkspaceEnabled ? filteredCustomFields : customFieldFilters

  const onClickNewRunbook = useCallback(() => {
    eventManager.emit('open-create-runbook-modal')
  }, [])

  useEffect(() => {
    return () => {
      timelineControllerRef.current?.unmount()
    }
  }, [])

  const {
    data,
    isLoading: isLoadingInitial,
    hasNextPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
    fetchPreviousPage
  } = useTimelineRunbooksQuery(search, {
    accountId,
    starts_after: startsAfter,
    onSuccess: ({ pages }) => {
      const maxValue = maxBy(pages, page => page.pagination.loadOrder)
      if (maxValue) {
        handleUpdateAngularRunbooksMeta(maxValue.meta)
      }
    }
  })

  const isLoading = Boolean(isLoadingInitial) || Boolean(isFetchingNextPage) || Boolean(isFetchingPreviousPage)
  const axisDefs = useMemo(() => {
    const result = data?.pages?.[(data?.pages.length || 1) - 1]?.axisDefs
    return result
  }, [data?.pages.length])

  return (
    <>
      {axisDefs && mountEl && (
        <RunbooksTimeline
          ref={timelineControllerRef}
          mountEl={mountEl}
          grouping={isReactWorkspaceEnabled ? (filters.group as AxisDefName) : grouping}
          spotlight={isReactWorkspaceEnabled ? (filters.spotlight as AxisDefName) : spotlight}
          accountId={accountId}
          initialStartDate={initialViewStartDate}
          initialEndDate={initialViewEndDate}
          axisDefs={{ ...axisDefs, ...getCustomFieldsAxisDef(customFieldsData) }}
          updateTimeline={updateTimeline}
          initTimeline={initTimeline}
          onClickNewRunbook={onClickNewRunbook}
          data={{
            rawData: data,
            // Sorting these inline to avoid another state variable triggering re-renders.
            // The sorting logic is such that the items are always going into the timeline controller in the order they are loaded from
            // the backend. Previous paged data (i.e., data with start dates prior the earliest of the initial loaded data) have the items
            // reversed each time a page is appended to the overall set. This is such that the timeline controller layout algorithm results
            // in the optimal display when computing the position of new items to the left.
            // TODO: Extract out that logic -- should not be something the react code is concerned with.
            runbooks: uniqBy(
              sortBy(data?.pages || [], 'pagination.loadOrder').flatMap(page => {
                return (page as PagedTimelineRunbooks).pagination.direction === 'prev'
                  ? [...addCustomFieldsFilterData((page as PagedTimelineRunbooks).runbooks, customFieldsData)].reverse()
                  : addCustomFieldsFilterData((page as PagedTimelineRunbooks).runbooks, customFieldsData)
              }),
              'id'
            ),
            isLoading,
            hasNextPage,
            hasPreviousPage,
            fetchNextPage,
            fetchPreviousPage
          }}
        />
      )}
    </>
  )
}

function getAccountIdFromUrl() {
  return window.location.hash.split('/')[2]
}
