/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { FieldOptionModelType } from 'main/data-access/models/types'
import { customFieldOptionsLookup, customFieldOptionsState } from 'main/recoil/runbook'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const getFieldOption: FieldOptionModelType['get'] = (identifier: number) => {
  const lookup = useRecoilValue(customFieldOptionsLookup)
  return lookup?.[identifier]
}

export const getFieldOptionCallback: FieldOptionModelType['getCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const lookup = await snapshot.getPromise(customFieldOptionsLookup)
    return lookup?.[identifier]
  })
}

export const getFieldOptionCallbackSync: FieldOptionModelType['getCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const lookup = snapshot.getLoadable(customFieldOptionsLookup).getValue()
    return lookup?.[identifier]
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const getFieldOptions: FieldOptionModelType['getAll'] = () => {
  return useRecoilValue(customFieldOptionsState)
}

export const getFieldOptionsCallback: FieldOptionModelType['getAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsState)
  )
}

export const getFieldOptionsCallbackSync: FieldOptionModelType['getAllCallbackSync'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(customFieldOptionsState).getValue()
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const getFieldOptionLookup: FieldOptionModelType['getLookup'] = () => {
  return useRecoilValue(customFieldOptionsLookup)
}

export const getFieldOptionLookupCallback: FieldOptionModelType['getLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldOptionsLookup)
  )

export const getFieldOptionLookupCallbackSync: FieldOptionModelType['getLookupCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(customFieldOptionsLookup).getValue()
  )
