import { CustomFieldModelType } from './types'
import {
  getCustomField,
  getCustomFieldCallback,
  getCustomFieldCallbackSync,
  getCustomFieldLookup,
  getCustomFields,
  getCustomFieldsCallback,
  getCustomFieldsCallbackSync,
  getCustomFieldsLookupCallback,
  getCustomFieldsLookupCallbackSync
} from '../hooks'

export const CustomFieldModel: CustomFieldModelType = {
  get: getCustomField,
  getCallback: getCustomFieldCallback,
  getCallbackSync: getCustomFieldCallbackSync,
  getAll: getCustomFields,
  getAllCallback: getCustomFieldsCallback,
  getAllCallbackSync: getCustomFieldsCallbackSync,
  getLookup: getCustomFieldLookup,
  getLookupCallback: getCustomFieldsLookupCallback,
  getLookupCallbackSync: getCustomFieldsLookupCallbackSync
}
