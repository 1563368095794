import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { toSnakeCase } from '@cutover/api'
import { EditPanel, IconButton, useNotify } from '@cutover/react-ui'
import { IntegrationActionItemForm } from './integration-action-item-form'
import {
  actionConfigSettingsVisibleAndRequired,
  buildDefaultValues,
  buildRequestData,
  buildValidationSchema,
  getItemTitle
} from './integration-action-item-form-helper'
import { IntegrationActionItemFormType, IntegrationActionItemRequestType } from './integration-action-item-types'
import { IntegrationArchiveModal } from '../shared-integration-components/integration-archive-modal'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import { useLanguage } from 'main/services/hooks/'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import {
  useIntegrationActionItemArchive,
  useIntegrationActionItemUpdate
} from 'main/services/queries/use-integration-action-item'
import { ConfigModel } from 'main/data-access'

export type EditIntegrationActionItemPanelProps = {
  integrationSetting: IntegrationSetting
  integrationActionItem: IntegrationActionItem
  onBack: () => void
  onClose: () => void
}

export const EditIntegrationActionItemPanel = ({
  integrationSetting,
  integrationActionItem,
  onBack,
  onClose
}: EditIntegrationActionItemPanelProps) => {
  const { t } = useLanguage('integrationSettings')
  const navigate = useNavigate()
  const { integrations, integrationHooks } = ConfigModel.get()
  const [panelTitle, setPanelTitle] = useState('')
  const [isArchiveModalOpen, setArchiveModalOpen] = useState(false)
  const [apiError, setApiError] = useState<string | undefined>(undefined)

  const currentIntegrationConfig = integrations?.find(integration => {
    return integrationSetting.integration === integration.klass
  })
  const availableActionItems = currentIntegrationConfig?.actions ?? []
  const selectedActionConfig = availableActionItems?.find(
    actionItem => integrationActionItem.integration_action === actionItem.klass
  )
  const mutation = useIntegrationActionItemUpdate(integrationActionItem.id, integrations)
  const archiveActionItemMutation = useIntegrationActionItemArchive(integrationActionItem.id)
  const defaultValues = buildDefaultValues(integrationActionItem, selectedActionConfig, integrationHooks)
  const validationSchema = buildValidationSchema(integrationActionItem, selectedActionConfig, t)

  const methods = useForm<IntegrationActionItemFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues
  })
  const {
    reset,
    watch,
    formState: { dirtyFields, isDirty, isSubmitting },
    getValues,
    handleSubmit
  } = methods
  const notify = useNotify()
  const isFormDirty = isDirty && !isEmpty(dirtyFields)

  const submitForm = async (values: IntegrationActionItemFormType) => {
    const data = buildRequestData(
      { ...values, id: integrationActionItem.id },
      integrationSetting,
      selectedActionConfig?.settings,
      dirtyFields
    )

    mutation.mutate(toSnakeCase(data as IntegrationActionItemRequestType), {
      onSuccess: () => {
        setPanelTitle(getItemTitle(values.integration_action, integrations))
        setApiError(undefined)
        notify.success(t('form.updatedActionItem.success'))
      },
      onError: error => {
        const castedError = error as unknown as ApiError
        if (castedError?.errors) {
          setApiError(castedError?.errors.join(' '))
        }
        notify.error(t('form.updatedActionItem.error'))
      }
    })

    reset(values)
  }

  const onSubmit = () => {
    handleSubmit(submitForm)()
  }

  const handleArchive = async () => {
    archiveActionItemMutation.mutate(undefined, {
      onSuccess: () => {
        notify.success(t('archiveToaster.actionConfirmation'))
        navigate(`../integration_connections?setting_id=${integrationActionItem.integration_setting.id}`)
      },
      onError: () => notify.error(t('archiveToaster.actionError'))
    })
  }

  const initForm = () => {
    reset(defaultValues)
  }

  useEffect(() => {
    initForm()
    setPanelTitle(t('editPanelTitle', { title: integrationActionItem.name }))
  }, [integrationActionItem, 'settings'])

  useEffect(() => {
    const { settings } = getValues()
    const selectedActionConfigSettings = selectedActionConfig?.settings
    actionConfigSettingsVisibleAndRequired(settings, selectedActionConfigSettings, getValues)
  }, [watch(['integration_action', 'settings'])])

  return (
    <EditPanel
      title={panelTitle}
      isDirty={isFormDirty}
      onSubmit={onSubmit}
      onReset={initForm}
      onBack={onBack}
      onClose={onClose}
      headerItems={[
        <IconButton label="Archive" tipPlacement="top" icon="trash-o" onClick={() => setArchiveModalOpen(true)} />
      ]}
      isSubmitting={isSubmitting}
    >
      <FormProvider {...methods}>
        <IntegrationActionItemForm
          integrationActionItem={integrationActionItem}
          defaultValues={defaultValues}
          apiError={apiError}
        />
      </FormProvider>
      <IntegrationArchiveModal
        isArchiveModalOpen={isArchiveModalOpen}
        onArchiveModalClose={() => setArchiveModalOpen(false)}
        onArchiveModalConfirm={handleArchive}
        title={integrationActionItem.name}
      />
    </EditPanel>
  )
}
