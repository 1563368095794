import { forwardRef, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { eventManager } from 'event-manager'
import { createPortal } from 'react-dom'
import { InfiniteData } from 'react-query'
import styled, { createGlobalStyle, css } from 'styled-components'

import { LegacyBox as Box, colors, NoResourceFound, px2rem } from '@cutover/react-ui'
import { RunbooksSummary } from './runbooks-summary'
import { TimelineController } from './timeline-controller'
import { AxisDefName, AxisDefs, RunbookData, SelectedDates, TimelineOptions } from './types'
import { useFilterSelected } from './use-filter-selected'
import { PagedTimelineRunbooks } from './use-runbooks-timeline-query'
import { useFilterPanelOpenState, useRightPanelLayoutOpenState } from 'main/components/layout/layout-hooks'
import { useAngularRightSidePanel } from '../../../../services/hooks'
import {
  useCloseRightPanelOnUnmount,
  useRightPanelTypeValue,
  useSetActiveRightPanelState
} from 'main/components/layout/right-panel'
import { ConfigModel } from 'main/data-access'

export type RunbooksTimelineProps = {
  accountId: string
  initialStartDate: number
  initialEndDate: number
  axisDefs: Partial<AxisDefs>
  grouping: AxisDefName | undefined
  spotlight: AxisDefName | undefined
  initTimeline: (opts: Partial<TimelineOptions>) => Function
  updateTimeline: (opts: Partial<TimelineOptions>) => void
  onClickNewRunbook: (event: SyntheticEvent) => void
  mountEl: Element
  data: {
    rawData?: InfiniteData<PagedTimelineRunbooks>
    runbooks: RunbookData[]
    isLoading: boolean
    hasNextPage?: boolean
    hasPreviousPage?: boolean
    fetchNextPage?: any
    fetchPreviousPage?: any
  }
}

/*
 * The timeline canvas rendering is controlled outside of the React cycle. This component
 * always renders null but calls useTimeline hook which will instantiate a
 * single TimelineController that will mount the canvas into the DOM.
 */
export const RunbooksTimeline = forwardRef<TimelineController, RunbooksTimelineProps>(
  (
    {
      initialStartDate,
      initialEndDate,
      axisDefs,
      initTimeline,
      updateTimeline,
      grouping,
      spotlight,
      mountEl,
      data: { rawData, runbooks, isLoading, hasNextPage, hasPreviousPage, fetchNextPage, fetchPreviousPage }
    },
    ref
  ) => {
    //TODO: investigate memoization
    useCloseRightPanelOnUnmount()
    const { openRightPanel, closeRightPanel } = useSetActiveRightPanelState()
    const { runbookId } = useRightPanelTypeValue('runbook-edit')
    const [viewStartEndDates, setViewStartEndDates] = useState({
      start: initialStartDate,
      end: initialEndDate
    })
    const [selectedDates, setSelectedDates] = useState<SelectedDates>({ start: null, end: null })
    const isReactWorkspaceEnabled = ConfigModel.isFeatureEnabled('react_workspace')
    const isRightPanelOpen = useRightPanelLayoutOpenState()
    const isFilterPanelOpen = useFilterPanelOpenState()

    const { sidePanelMountElement, sidePanelOpenState, closeAngularSidePanel, openAngularSidePanel } =
      useAngularRightSidePanel({
        trackState: true,
        onClosed: () => {
          setSelectedDates({ start: null, end: null })
        }
      })

    useEffect(() => {
      if (typeof ref === 'function') return
      let unsubscribe: Function | undefined = undefined

      if (!ref?.current) {
        unsubscribe = initTimeline({
          dimensionY: grouping,
          data: runbooks,
          axisDefs: axisDefs,
          onRescaleEnd: ({ startDate, endDate }) => {
            setViewStartEndDates({
              start: startDate,
              end: endDate
            })
          },
          onDateSelect: dates => {
            if (typeof dates.startDate !== 'number') {
              if (isReactWorkspaceEnabled) {
                closeRightPanel()
              }
            }
            closeAngularSidePanel() //resets panel when clicking between summary and edit runbook
            setSelectedDates({
              start: dates.startDate,
              end: dates.endDate
            })
          },
          onSelectionChange: runbookData => handleSelectionChange(runbookData)
        })
      }

      return () => {
        unsubscribe?.()
      }
    }, [])

    useEffect(() => {
      if (typeof ref === 'function') return

      const pagedLoadedRunbooks = rawData?.pages.flatMap(page => page?.runbooks) || []
      const latestStartingRunbook = pagedLoadedRunbooks[pagedLoadedRunbooks.length - 1]
      const earliestStartingRunbook = pagedLoadedRunbooks[0]

      if (ref?.current && !isLoading) {
        if (
          hasNextPage &&
          (!pagedLoadedRunbooks.length || isBefore(viewStartEndDates.end, latestStartingRunbook?.start_display))
        ) {
          fetchNextPage()
        } else if (
          hasPreviousPage &&
          (!pagedLoadedRunbooks.length || isAfter(viewStartEndDates.start, earliestStartingRunbook?.start_display))
        ) {
          fetchPreviousPage()
        }
      }
    }, [hasNextPage, hasPreviousPage, rawData, viewStartEndDates, isLoading, fetchNextPage, fetchPreviousPage])

    useEffect(() => {
      updateTimeline({
        data: runbooks,
        selectedEndDate: selectedDates.end,
        selectedStartDate: selectedDates.start,
        dimensionY: grouping,
        spotlight: spotlight,
        axisDefs
      })
    }, [selectedDates, runbooks, axisDefs, grouping, spotlight])

    useEffect(() => {
      if (typeof ref === 'function') return

      let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined

      if (sidePanelOpenState === 'closed' || !isRightPanelOpen) {
        ref?.current?.deselectItems()

        timeoutId = setTimeout(() => {
          ref?.current?.onResize()
        }, 400)
      }

      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId)
        }
      }
    }, [sidePanelOpenState, isRightPanelOpen, isFilterPanelOpen])

    const selectedRunbooks = useFilterSelected({ items: runbooks, dates: selectedDates })

    useEffect(() => {
      if (!isReactWorkspaceEnabled) return

      if ((selectedRunbooks || []).length) {
        openRightPanel({
          type: 'runbooks-timeline-summary',
          selectedDates,
          runbooks: selectedRunbooks || []
        })
      }
    }, [selectedRunbooks, isReactWorkspaceEnabled])

    // TODO: remove when react workspace FF removed
    useEffect(() => {
      // useEffect for legacy only
      if (isReactWorkspaceEnabled) return

      if (selectedRunbooks && sidePanelOpenState === 'closed') {
        openAngularSidePanel({ content: 'runbook-timeline-summary' })
      }
    }, [isReactWorkspaceEnabled, selectedRunbooks])

    const handleSelectRunbook = (id: number) => {
      if (isReactWorkspaceEnabled) {
        return openRightPanel({ type: 'runbook-edit', runbookId: id })
      } else {
        // TODO: remove when react workspace FF removed
        closeAngularSidePanel()
        openAngularSidePanel({ content: 'runbook-edit', contentId: id })
      }
    }

    // TODO: remove when react workspace FF removed
    const handleSelectionChange = ({ ids }: { ids: number[] }) => {
      closeAngularSidePanel() // resets panel when clicking between summary and edit panel
      if (ids.length) {
        handleSelectRunbook(ids[0])
      }
    }

    const clearFilters = useCallback(() => {
      eventManager.emit('clear-runbook-filters')
    }, [])

    const showNoRunbooksIndicator = useMemo(() => {
      return !runbooks.length && !isLoading
    }, [runbooks, isLoading])

    return (
      <>
        <GlobalTimelineStyles isReactWorkspace={isReactWorkspaceEnabled} />
        {sidePanelMountElement &&
          selectedRunbooks &&
          !runbookId &&
          createPortal(
            <RunbooksSummary
              onSelectRunbook={handleSelectRunbook}
              onClickClose={closeAngularSidePanel}
              runbooks={selectedRunbooks}
              selectedDates={selectedDates}
            />,
            sidePanelMountElement
          )}
        {mountEl &&
          createPortal(
            <>
              {showNoRunbooksIndicator && (
                <NoRunbooksIndicator isReactWorkspace={isReactWorkspaceEnabled}>
                  <Box
                    css={`
                      padding: ${px2rem(28)};
                    `}
                  >
                    <NoResourceFound clearAllFilters={clearFilters} context={'runbook'} />
                  </Box>
                </NoRunbooksIndicator>
              )}
              {/* TODO: add permission conditional on add button */}
              {/* <Button iconName={'add'} onClick={onClickNewRunbook} displayStyle={'add-button'} />, */}
            </>,
            mountEl
          )}
      </>
    )
  }
)

const NoRunbooksIndicator = styled.div<{ isReactWorkspace: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${colors.white};
  ${({ isReactWorkspace }) => isReactWorkspace && 'margin-left: -16px;'}
`

//===================== utils ================================

function isBefore(date: number, testDate?: number | null) {
  if (!testDate) return false

  return testDate < date
}

function isAfter(date: number, testDate?: number | null) {
  if (!testDate) return false

  return testDate > date
}

// Hack to give the timeline the currently necessary layout adjustments specific to the react workspace flag
const GlobalTimelineStyles = createGlobalStyle<{ isReactWorkspace: boolean }>`
  ${props =>
    props.isReactWorkspace &&
    css`
      .timeline-canvas {
        left: 0;
      }
    `}
`
