import { useContext } from 'react'
import { parseISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { sortBy } from 'lodash'

import { BarChart, colors } from '@cutover/react-ui'
import { createChartDeltas } from './chart-deltas'
import { DashboardContext } from 'main/components/dashboards/runbooks-dashboard-page'
import { NoDataAvailable } from 'main/components/dashboards/widgets/account/common'
import { MrdDashboardWidget } from 'main/components/dashboards/widgets/account/mrd-dashboard-widget'
import { getFilterParams } from 'main/components/dashboards/widgets/account/mrd-filter-util'
import { DashboardComponentProps } from 'main/components/dashboards/widgets/types'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useAngularRightSidePanel } from 'main/services/hooks'
import { ConfigModel } from 'main/data-access'

type Delta = {
  duration: number
  percentage: number
}

type AverageRunbookDurationApi = {
  timestamp: string
  average_runbook_duration: number
  duration: string
  delta?: Delta
}

export type AverageRunbookDurationOverTimeWidgetData = {
  name: string
  values: AverageRunbookDurationApi[]
}

export type AverageRunbookDurationOverTimeWidgetProps =
  DashboardComponentProps<AverageRunbookDurationOverTimeWidgetData>

export const AverageRunbookDurationOverTimeWidget = (props: AverageRunbookDurationOverTimeWidgetProps) => {
  const context = props.media

  switch (context) {
    case 'screen':
      return <BrowserWidget {...props} />
    case 'email':
      return <EmailWidget {...props} />
    case 'print':
      return <PrintWidget {...props} />
  }
}

const BrowserWidget = ({ data }: { data: AverageRunbookDurationOverTimeWidgetData }) => {
  const isReactWorkspaceEnabled = ConfigModel.isFeatureEnabled('react_workspace')

  return <WidgetContent data={data} reactWorkspace={isReactWorkspaceEnabled} />
}

const EmailWidget = ({ data }: { data: AverageRunbookDurationOverTimeWidgetData }) => (
  <WidgetContent data={data} width={550} />
)

const PrintWidget = ({ data }: { data: AverageRunbookDurationOverTimeWidgetData }) => (
  <WidgetContent data={data} width={850} />
)

function WidgetContent({
  data,
  width,
  reactWorkspace = false
}: {
  data: AverageRunbookDurationOverTimeWidgetData
  width?: number
  reactWorkspace?: boolean
}) {
  const { accountId, dashboardId, filterParams } = useContext(DashboardContext)
  const { openAngularSidePanel } = useAngularRightSidePanel()
  const { openRightPanel } = useSetActiveRightPanelState()

  const values: AverageRunbookDurationApi[] = data.values

  const durations = (values ?? []).map(e => ({
    date: parseISO(e.timestamp.substring(0, 11)), // Ignore time to avoid midnight conversion to local time
    hours: e.average_runbook_duration,
    delta: e.delta
  }))

  const isEmpty = durations.length === 0 || durations.every(d => d.hours === 0)

  return (
    <MrdDashboardWidget title={data.name} fullWidth>
      {isEmpty ? (
        <NoDataAvailable />
      ) : (
        <AverageRunbookDurationOverTime
          yAxisTitle="Average (Hours)"
          durations={durations}
          width={width}
          onSelect={date => {
            const contentId = {
              accountId,
              dashboardId,
              ...getFilterParams(filterParams),
              stage: ['complete'],
              run_type: ['live'],
              start_actual_month: [format(date, 'yyyy-MM-dd')],
              subFilterKey: 'start_actual_month',
              subFilterValue: format(date, 'yyyy-MM-dd')
            }

            if (reactWorkspace) {
              openRightPanel({ type: 'dashboard-runbook-list', params: contentId })
            } else {
              openAngularSidePanel({ content: 'filtered-changes', contentId })
            }
          }}
        />
      )}
    </MrdDashboardWidget>
  )
}

export type RunbookDuration = {
  date: Date
  hours: number
  delta?: Delta
}

export type AverageRunbookDurationOverTimeProps = {
  yAxisTitle: string
  durations: RunbookDuration[]
  onSelect?: (date: Date, event: any) => void
  width?: number
}

export const AverageRunbookDurationOverTime = ({
  yAxisTitle,
  durations,
  onSelect,
  width
}: AverageRunbookDurationOverTimeProps) => {
  const sortedDurations = sortBy(durations, e => e.date).map(e => ({
    category: {
      name: 'Date',
      date: e.date,
      delta: e.delta,
      value: format(e.date, 'LLLL')
    },
    values: [
      {
        group: 'Hours',
        value: e.hours
      }
    ]
  }))

  // BarChart doesn't currently support nivo xAxis time format or xFormat props
  // The index of the selected bar is used to look up stored date object
  return (
    <BarChart
      data={sortedDurations}
      height={400}
      width={width}
      padding={'30px 0 30px 50px'}
      colors={{
        Hours: colors.primary
      }}
      xAxis={{
        id: 'Date',
        label: ''
      }}
      yAxis={{
        id: 'Hours',
        label: yAxisTitle
      }}
      layers={[
        createChartDeltas(
          sortedDurations.map(d => ({
            id: d.category.value,
            delta: d.category.delta
          }))
        )
      ]}
      onSelect={(data, event) => {
        const date = sortedDurations[data.index]?.category?.date

        if (date) {
          onSelect?.(date, event)
        }
      }}
    />
  )
}
