import {
  useRunbookType,
  useRunbookTypeCallback,
  useRunbookTypeCallbackSync,
  useRunbookTypeFrom,
  useRunbookTypeFromCallback,
  useRunbookTypeFromCallbackSync,
  useRunbookTypeLookup,
  useRunbookTypeLookupCallback,
  useRunbookTypeLookupCallbackSync,
  useRunbookTypes,
  useRunbookTypesCallback,
  useRunbookTypesCallbackSync
} from '../hooks/recoil/runbook-type'
import { RunbookTypeModelType } from './types'

export const RunbookTypeModel: RunbookTypeModelType = {
  get: useRunbookType,
  getCallback: useRunbookTypeCallback,
  getCallbackSync: useRunbookTypeCallbackSync,
  getAll: useRunbookTypes,
  getAllCallback: useRunbookTypesCallback,
  getAllCallbackSync: useRunbookTypesCallbackSync,
  getLookup: useRunbookTypeLookup,
  getLookupCallback: useRunbookTypeLookupCallback,
  getLookupCallbackSync: useRunbookTypeLookupCallbackSync,
  getFrom: useRunbookTypeFrom,
  getFromCallback: useRunbookTypeFromCallback,
  getFromCallbackSync: useRunbookTypeFromCallbackSync
}
