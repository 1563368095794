import { format, utcToZonedTime } from 'date-fns-tz'

import { useAccountCustomFieldLookups } from 'main/services/api/data-providers/account/account-data'
import { CustomField, CustomFieldUser, FieldOption, FieldValue } from 'main/services/queries/types'
import { CustomFieldModel, CustomFieldUserModel, FieldOptionModel } from 'main/data-access'

export type CustomFieldPillLabelType = {
  label: string
  color?: string
}

export const useTaskListCustomFieldPillLabels = ({
  fieldValues,
  timezone
}: {
  fieldValues: FieldValue[]
  timezone: string | null
}) => {
  const fieldOptionLookup = FieldOptionModel.getLookup()
  const customFieldsLookup = CustomFieldModel.getLookup()
  const cfUserLookup = CustomFieldUserModel.getLookup()

  const lookups = {
    fieldOptionLookup,
    customFieldsLookup,
    customFieldUserLookup: cfUserLookup
  }

  return useCustomFieldPillLabels({
    fieldValues,
    timezone,
    lookups
  })
}

export const useRunbokListCustomFieldPillLabels = ({
  fieldValues,
  timezone
}: {
  fieldValues: FieldValue[]
  timezone: string | null
}) => {
  const lookups = useAccountCustomFieldLookups()
  return useCustomFieldPillLabels({
    fieldValues,
    timezone,
    lookups
  })
}

const useCustomFieldPillLabels = ({
  fieldValues,
  timezone,
  lookups
}: {
  fieldValues: FieldValue[]
  timezone: string | null
  lookups: {
    fieldOptionLookup: Record<number, FieldOption> | undefined
    customFieldsLookup: Record<number, CustomField> | undefined
    customFieldUserLookup: Record<number, CustomFieldUser> | undefined
  }
}) => {
  const { fieldOptionLookup, customFieldsLookup, customFieldUserLookup } = lookups

  const customFieldPillLabels: CustomFieldPillLabelType[] = []

  if (!fieldValues.length || !fieldOptionLookup || !customFieldsLookup || !customFieldUserLookup) {
    return { customFieldPillLabels }
  }

  const handlePrimaryOrRadioOrSelect = ({
    fieldValue,
    customField
  }: {
    fieldValue: FieldValue
    customField: CustomField
  }) => {
    if (fieldValue.field_option_id) {
      const fieldOption = fieldOptionLookup[fieldValue.field_option_id]
      customFieldPillLabels.push({
        label: `${customField.display_name}: ${fieldOption?.name}`,
        color: fieldOption?.color || undefined
      })
    }
  }

  fieldValues.forEach(field_value => {
    const customField = customFieldsLookup[field_value.custom_field_id]

    if (!customField?.archived && customField?.display_list) {
      if (customField.is_primary && customField.parent_field_id !== null) {
        handlePrimaryOrRadioOrSelect({ fieldValue: field_value, customField: customField })
      } else {
        switch (customField.field_type.slug) {
          case 'radiobox':
          case 'select_menu':
            handlePrimaryOrRadioOrSelect({ fieldValue: field_value, customField: customField })
            break
          case 'checkboxes': {
            if (field_value.value) {
              const valueArray = JSON.parse(field_value.value)
              const fieldOption = fieldOptionLookup[valueArray[0]] // display first one
              if (valueArray.length) {
                if (valueArray.length === 1) {
                  const label = `${customField.display_name}: ${fieldOption.name}`
                  const color = fieldOption.color || undefined
                  customFieldPillLabels.push({ label, color })
                } else {
                  const label = `${customField.display_name}: ${fieldOption.name} +${valueArray.length - 1} more`
                  const color = fieldOption.color || undefined
                  customFieldPillLabels.push({ label, color })
                }
              }
            }
            break
          }
          case 'text':
          case 'searchable':
          case 'multi_searchable':
            customFieldPillLabels.push({
              label: `${customField.display_name}: ${field_value.computed_value ?? field_value.value}`
            })
            break
          case 'datetime': {
            if (field_value.value) {
              customFieldPillLabels.push({
                label: `${customField.display_name}: ${format(
                  timezone ? utcToZonedTime(new Date(field_value.value), timezone) : new Date(field_value.value),
                  'EEE, dd LLL yyyy'
                )}`
              })
            }
            break
          }
          case 'user_select': {
            if (field_value.value) {
              const valueArray = JSON.parse(field_value.value)
              if (valueArray.length) {
                const firstUser = customFieldUserLookup[valueArray[0]]
                // FIXME: this is a hack to work around that we don't have in the data store any new custom field users that have been added when editing a runbook until a new
                // fetch of the account data because it only holds a collection of users that exist on custom field selects on any runbook in the account at the time the account loads.
                // We could optimistically update the account store's custom field users on mutate for the runbook edit.
                if (!firstUser) return
                if (valueArray.length === 1) {
                  const label = `${customField.display_name}: ${firstUser.name}`
                  customFieldPillLabels.push({ label })
                } else {
                  const label = `${customField.display_name}: ${firstUser.name} +${valueArray.length - 1} more`
                  customFieldPillLabels.push({ label })
                }
              }
            }
            break
          }
          case 'endpoint':
          case 'textarea':
            break
        }
      }
    }
  })

  return { customFieldPillLabels }
}
