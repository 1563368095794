import {
  getFieldOption,
  getFieldOptionCallback,
  getFieldOptionCallbackSync,
  getFieldOptionLookup,
  getFieldOptionLookupCallback,
  getFieldOptionLookupCallbackSync,
  getFieldOptions,
  getFieldOptionsCallback,
  getFieldOptionsCallbackSync
} from '../hooks/recoil/field-option'
import { FieldOptionModelType } from './types'

export const FieldOptionModel: FieldOptionModelType = {
  get: getFieldOption,
  getCallback: getFieldOptionCallback,
  getCallbackSync: getFieldOptionCallbackSync,
  getAll: getFieldOptions,
  getAllCallback: getFieldOptionsCallback,
  getAllCallbackSync: getFieldOptionsCallbackSync,
  getLookup: getFieldOptionLookup,
  getLookupCallback: getFieldOptionLookupCallback,
  getLookupCallbackSync: getFieldOptionLookupCallbackSync
}
