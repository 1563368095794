import { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { AccordionPanel, Checkbox, RadioboxGroup } from '@cutover/react-ui'
import { RunbookEditFormType } from './runbook-edit-form'
import { useIsTemplateLocked } from 'main/components/shared/hooks/runbook'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook, RunbookTypeType } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

type RunbookEditDisplaySettingsPanelProps = {
  runbook: RunbookEditRunbook
  runbookType: RunbookTypeType
  runbookTypes: RunbookTypeType[]
  disabled?: boolean
  readOnly?: boolean
}

export const RunbookEditDisplaySettingsPanel = memo(
  ({ runbook, runbookType, runbookTypes, disabled, readOnly }: RunbookEditDisplaySettingsPanelProps) => {
    const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })

    const lockToFolderOptionEnabled = ConfigModel.isFeatureEnabled('lock_to_folder_option')
    const { control, register, getValues } = useFormContext<RunbookEditFormType>()
    // Ref CFE-560: incident runbook type should not be available
    const availableRunbookTypes = runbookTypes?.filter(
      rt =>
        runbook.runbook_type_id === rt.id ||
        (!rt.archived && !rt.disabled && rt.key !== 'incident-runbook' && rt.key !== 'snippet')
    )
    const changeRequestTypes = runbookType.change_request_types
    const isTemplateLocked = useIsTemplateLocked(runbook)
    const isIncidentRunbook = runbookType.key === 'incident-runbook'
    const isDisabled = disabled || changeRequestTypes.length > 0 || isTemplateLocked

    return (
      <AccordionPanel label={t('displaySettings.label')} icon="visibility-show" iconColor={runbook.project.color}>
        {!isIncidentRunbook && (
          <Controller
            name="runbook.runbook_type_id"
            control={control}
            render={({ field: { name, onChange, value, onBlur, ref } }) => (
              <RadioboxGroup
                name={name}
                label={t('displaySettings.runbookType.label')}
                direction="column"
                disabled={isDisabled}
                readOnly={readOnly}
                onChange={e => onChange(Number(e.target.value))}
                value={Number(value)}
                onBlur={onBlur}
                ref={ref}
                options={
                  availableRunbookTypes?.map(runbookType => ({
                    value: runbookType.id,
                    label: `${runbookType.name}${
                      runbookType.disabled ? ` ${t('displaySettings.runbookType.disabledIndicator')}` : ''
                    }`
                  })) ?? []
                }
              />
            )}
          />
        )}
        <Checkbox
          checked={Boolean(getValues('runbook.settings_team_in_task_list'))}
          {...register('runbook.settings_team_in_task_list')}
          label={t('displaySettings.checkboxOptions.showTeam.label')}
          disabled={isDisabled || readOnly} // TODO: distinguish between disabled and readOnly
        />
        <Checkbox
          checked={Boolean(getValues('runbook.settings_substreams_inherit_color'))}
          {...register('runbook.settings_substreams_inherit_color')}
          disabled={isDisabled || readOnly} // TODO: distinguish between disabled and readOnly
          label={t('displaySettings.checkboxOptions.substreamsInherit.label')}
        />
        <Checkbox
          checked={Boolean(getValues('runbook.settings_task_description_on_task_start'))}
          {...register('runbook.settings_task_description_on_task_start')}
          label={t('displaySettings.checkboxOptions.showTaskDescription.label')}
          disabled={isDisabled || readOnly} // TODO: distinguish between disabled and readOnly
        />
        {lockToFolderOptionEnabled && (
          <Checkbox
            checked={Boolean(getValues('runbook.settings_lock_template_copies_to_folder'))}
            {...register('runbook.settings_lock_template_copies_to_folder')}
            label={t('displaySettings.checkboxOptions.lockCopiesToFolder.label')}
            data-testid={t('displaySettings.checkboxOptions.lockCopiesToFolder.label')}
            disabled={isDisabled || readOnly} // TODO: distinguish between disabled and readOnly
          />
        )}
      </AccordionPanel>
    )
  }
)
