/* eslint-disable react-hooks/rules-of-hooks */
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { CustomFieldModelType } from 'main/data-access/models/types'
import { accountCustomFieldsState, customFieldLookup } from 'main/recoil/runbook'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const getCustomField: CustomFieldModelType['get'] = (identifier: number) => {
  const lookup = useRecoilValue(customFieldLookup)

  return lookup[identifier]
}

export const getCustomFieldCallback: CustomFieldModelType['getCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const lookup = await snapshot.getPromise(customFieldLookup)
    return lookup?.[identifier]
  })
}

export const getCustomFieldCallbackSync: CustomFieldModelType['getCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const lookup = snapshot.getLoadable(customFieldLookup).getValue()
    return lookup?.[identifier]
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Get All                                   */
/* -------------------------------------------------------------------------- */

export const getCustomFields: CustomFieldModelType['getAll'] = () => {
  return useRecoilValue(accountCustomFieldsState)
}

export const getCustomFieldsCallback: CustomFieldModelType['getAllCallback'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(accountCustomFieldsState)
  )
}

export const getCustomFieldsCallbackSync: CustomFieldModelType['getAllCallbackSync'] = () => {
  return useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(accountCustomFieldsState).getValue()
  )
}

/* -------------------------------------------------------------------------- */
/*                                  Lookup                                    */
/* -------------------------------------------------------------------------- */

export const getCustomFieldLookup: CustomFieldModelType['getLookup'] = () => {
  return useRecoilValue(customFieldLookup)
}

export const getCustomFieldsLookupCallback: CustomFieldModelType['getLookupCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getPromise(customFieldLookup)
  )

export const getCustomFieldsLookupCallbackSync: CustomFieldModelType['getLookupCallbackSync'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(customFieldLookup).getValue()
  )
