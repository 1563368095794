import { useContext, useEffect, useState } from 'react'

import { LinkTemplateBulkCreateOptionsModal } from './link-template-bulk-create-options-modal'
import { LinkTemplateSelectModal } from './link-template-select-modal'
import { AccountDataContext } from 'main/services/api/data-providers/account/account-data-provider'
import { TaskListTask, RunbookTemplateRunbook as Template } from 'main/services/queries/types'
import { TaskBulkCreateData, useTasksBulkCreate } from 'main/services/queries/use-tasks-bulk-create'
import { useLanguage } from 'main/services/hooks'
import { useProcessTaskBulkCreateResponse } from 'main/recoil/data-access/updaters__TEMPORARY/task-operations'
import { ConfigModel } from 'main/data-access'

export type LinkTemplateSelectWizardProps = {
  onClose: () => void
  accountId: string
  onSubmit: () => void
  task: TaskListTask
  runbookId: string
  runbookVersionId: string
}

type BulkCreateTaskFormProps = {
  task_type: number
  order: 'sequence' | 'parallel'
}

export const LinkTemplateSelectWizard = ({
  onClose,
  accountId,
  runbookId,
  runbookVersionId,
  task
}: LinkTemplateSelectWizardProps) => {
  const [location, setLocation] = useState<'templateSelect' | 'createOptions'>('templateSelect')
  const [selectedTemplates, setSelectedTemplates] = useState<number[] | undefined>()
  const accountData = useContext(AccountDataContext)
  const mutation = useTasksBulkCreate(runbookId, runbookVersionId, task.id as number)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { t } = useLanguage('runbook', { keyPrefix: 'bulkCreateTaskOptionsModal' })
  const isReactRunbookEnabled = ConfigModel.isFeatureEnabled('react_runbook')
  const processTaskBulkCreateResponse = useProcessTaskBulkCreateResponse()

  const handleTemplateSelect = (templates: Template[]) => {
    setLocation('createOptions')
    setSelectedTemplates(templates.map(t => t.id))
  }

  const getBackToTemplateSelectModal = () => {
    setLocation('templateSelect')
  }

  const handleLinkSubmit = async (options: BulkCreateTaskFormProps) => {
    setIsLoading(true)
    // Note: this makes no sense why we are posting the task names/task type ids here
    // That is the only reason this comp needs to be passed a full task rather than just id
    const taskBulkCreateData: TaskBulkCreateData = {
      task_data: selectedTemplates?.map(template_id => ({
        task: { name: task.name, task_type_id: options.task_type },
        linked_resource: { id: template_id, type: 'Runbook' }
      })),
      create_type: options.order
    }

    mutation.mutate(taskBulkCreateData, {
      onSuccess: (response: any) => {
        if (isReactRunbookEnabled) {
          processTaskBulkCreateResponse(response)
        }
        onClose()
      },
      onError: (error: any) => {
        const { errors } = error
        setErrorMsg(errors[0] || t('errorMessage'))
        setIsLoading(false)
        setIsError(true)
      }
    })
  }

  useEffect(() => {
    if (location === 'templateSelect') {
      setIsLoading(false)
      setIsError(false)
    }
  }, [location])

  return location === 'templateSelect' ? (
    <LinkTemplateSelectModal
      runbookId={runbookId}
      selectedTemplateData={selectedTemplates}
      multiSelect
      onClose={onClose}
      onSubmit={handleTemplateSelect}
      accountId={accountId}
    />
  ) : (
    <LinkTemplateBulkCreateOptionsModal
      isLoading={isLoading}
      isError={isError}
      taskTypes={accountData.taskTypes}
      onSubmit={handleLinkSubmit}
      onBack={getBackToTemplateSelectModal}
      onClose={onClose}
      errorMsg={errorMsg}
    ></LinkTemplateBulkCreateOptionsModal>
  )
}
