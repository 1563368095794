import { useState } from 'react'
import styled from 'styled-components/macro'

import { Box, Button, Heading, IconButton, Menu, MenuListItem, Text, useNotify } from '@cutover/react-ui'
import { NewIntegrationActionItemModal } from './action-items/new-integration-action-item-modal'
import { useLanguage } from 'main/services/hooks'
import { IntegrationActionItem, IntegrationSetting } from 'main/services/queries/types'
import { saveFile } from 'Shared/Helpers'
import { IntegrationActionItemsImportModal } from './integration-action-items-import-modal'
import { IntegrationActionItemsBulkExportModal } from './integration-action-items-bulk-export-modal'
import { useIntegrationActionItemsExport } from 'main/services/queries/use-integration-settings'
import { ConfigModel } from 'main/data-access'

export type IntegrationActionItemListProps = {
  actionItems: IntegrationActionItem[] | undefined
  integrationSetting: IntegrationSetting
  isLoading: boolean
  onSelectActionItem: (actionItem: IntegrationActionItem) => void
}

export const IntegrationActionItemList = ({
  actionItems,
  integrationSetting,
  isLoading,
  onSelectActionItem
}: IntegrationActionItemListProps) => {
  const notify = useNotify()
  const isPortableIntegrationsEnabled = ConfigModel.isFeatureEnabled('portable_integrations')
  const [isModalOpen, setModalOpen] = useState(false)
  const [integrationActionItemsImportModalOpen, setIntegrationActionItemsImportModalOpen] = useState(false)
  const [integrationActionItemsBulkExportModalOpen, setIntegrationActionItemsBulkExportModalOpen] = useState(false)
  const { integrationHooks } = ConfigModel.get()
  const { t } = useLanguage('integrationSettings', { keyPrefix: 'actionItemList' })

  const sortedActionItems = actionItems?.sort((a, b) => a.id - b.id) as IntegrationActionItem[]

  const getHookNames = (triggers: string[]) => {
    const foundHooks = integrationHooks?.filter(hook => triggers.indexOf(hook.on) !== -1)
    return foundHooks?.map(hook => hook.name).join(', ') || ''
  }
  const {
    mutateAsync: mutateExportAsync,
    isLoading: isExportLoading,
    isError: isExportError,
    isSuccess: isExportSuccess,
    error: exportError,
    reset: exportReset
  } = useIntegrationActionItemsExport()

  const exportIntegrationActionItems = (selectedIntegrationActionItemsId: number[]) => {
    const filename = `integration-action-items-${selectedIntegrationActionItemsId.join('-')}.yml`

    mutateExportAsync({ id: integrationSetting.id, selectedIntegrationActionItemsId: selectedIntegrationActionItemsId })
      .then((data: string) => {
        notify.success(t('exportToaster.actionConfirmation'))
        saveFile(data, 'application/x-yaml', filename)
      })
      .catch(() => {
        notify.error(t('exportToaster.actionError'))
      })
  }

  return (
    <Box>
      <Box align="center" justify="between" margin={{ top: '24px', bottom: '16px' }} direction="row">
        <Heading as="h4" css="font-size: 18px;">
          {t('actions')}
        </Heading>
        <Box align="center" direction="row" margin={{ left: 'xsmall' }}>
          <Button primary label={t('new')} onClick={() => setModalOpen(true)} icon="add" loading={isLoading} />
          {isPortableIntegrationsEnabled && (
            <Menu
              trigger={
                <IconButton
                  tertiary
                  data-testid="integration-settings-more-options-menu"
                  label="More options"
                  disableTooltip
                  icon="more-vertical"
                />
              }
            >
              <MenuListItem
                label={t('moreOptions.importIntegrationActionItems')}
                key={t('moreOptions.importIntegrationActionItems')}
                data-testid="import-action-option"
                icon="import"
                onClick={() => setIntegrationActionItemsImportModalOpen(true)}
              />
              {sortedActionItems && sortedActionItems.length > 0 && (
                <MenuListItem
                  label={t('moreOptions.exportBulkIntegrationActionItems')}
                  key={t('moreOptions.exportBulkIntegrationActionItems')}
                  data-testid="export-bulk-actions-option"
                  icon="export"
                  onClick={() => setIntegrationActionItemsBulkExportModalOpen(true)}
                />
              )}
            </Menu>
          )}
        </Box>
      </Box>
      {!isLoading && sortedActionItems && sortedActionItems.length > 0 && (
        <Box>
          <IntegrationActionItemsTable>
            <thead>
              <tr>
                <th>{t('name')}</th>
                <th style={{ width: '35%' }}>{t('trigger')}</th>
                {isPortableIntegrationsEnabled && <th style={{ width: '30px' }} />}
                <th style={{ width: '30px' }} />
              </tr>
            </thead>
            <tbody>
              {sortedActionItems.map(item => (
                <tr key={item.id}>
                  <td>
                    <Text tag="div" truncate="tip">
                      {item.name}
                    </Text>
                  </td>
                  <td>
                    <Text tag="div" truncate="tip">
                      {getHookNames(item?.on)}
                    </Text>
                  </td>
                  {isPortableIntegrationsEnabled && (
                    <td>
                      <IconButton
                        label="Export action"
                        tipPlacement="top"
                        size="small"
                        icon="export"
                        onClick={() => exportIntegrationActionItems([item.id])}
                      />
                    </td>
                  )}
                  <td>
                    <IconButton
                      icon="settings"
                      size="small"
                      label="Settings"
                      onClick={() => onSelectActionItem(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </IntegrationActionItemsTable>
        </Box>
      )}
      <NewIntegrationActionItemModal
        integrationSetting={integrationSetting}
        headerLabel={`New ${integrationSetting?.name} Action`}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
      <IntegrationActionItemsImportModal
        integrationActionItemsImportModalOpen={integrationActionItemsImportModalOpen}
        onIntegrationActionItemsImportModalClose={() => setIntegrationActionItemsImportModalOpen(false)}
        integrationSetting={integrationSetting}
      />
      <IntegrationActionItemsBulkExportModal
        integrationActionItemsBulkExportModalOpen={integrationActionItemsBulkExportModalOpen}
        integrationActionItemsBulkExportModalClose={() => setIntegrationActionItemsBulkExportModalOpen(false)}
        exportIntegrationActionItems={exportIntegrationActionItems}
        integrationActionItems={sortedActionItems}
        isLoading={isExportLoading}
        isError={isExportError}
        isSuccess={isExportSuccess}
        error={exportError}
        reset={exportReset}
      />
    </Box>
  )
}

export const IntegrationActionItemsTable = styled.table`
  table-layout: fixed;
  width: 100%;
  text-align: left;
  th,
  td {
    padding: 6px 0;
  }
`
