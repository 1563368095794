import { useMemo } from 'react'
import { format as formatDate, isSameDay } from 'date-fns'
import styled from 'styled-components'

import { Box, colors, DonutChart, px2rem, transition } from '@cutover/react-ui'
import { RunbooksSummaryItem } from './runbooks-summary-item'
import { RunbookData, SelectedDates } from './types'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { Icon } from 'Shared/Components/Atoms/Icon'
import { ConfigModel } from 'main/data-access'

type RunbookTimelineSummaryProps = {
  onSelectRunbook?: (id: number) => void
  onClickClose?: () => void
  selectedDates: SelectedDates
  runbooks: RunbookData[]
}

type DonutSection = {
  id: string | number
  label: string | number
  name: string | number
  value: number
  color: string
}

// TODO remove props when no longer maintaining nested react in angular panel (aka. workspace FF removed)
export function RunbooksSummary({
  onSelectRunbook,
  runbooks,
  selectedDates,
  onClickClose
}: RunbookTimelineSummaryProps) {
  const { openRightPanel } = useSetActiveRightPanelState()

  const isReactWorkspaceEnabled = ConfigModel.isFeatureEnabled('react_workspace')
  const { translate } = useLanguage()

  const dateRangeContent = useMemo(() => {
    if (!selectedDates) return

    if (selectedDates.start && selectedDates.end) {
      const startDate = new Date(selectedDates.start * 1000)
      const endDate = new Date(selectedDates.end * 1000)

      const dateString = isSameDay(startDate, endDate)
        ? formatDate(startDate, 'd MMM')
        : `${formatDate(startDate, 'd MMM')} - ${formatDate(endDate, 'd MMM')}`
      return <HeadingDate>{dateString}</HeadingDate>
    }
  }, [selectedDates])

  const startingFinishingLabel = translate('runbooks:timelineSummary:summaryChart:startingFinishing')
  const startingLabel = translate('runbooks:timelineSummary:summaryChart:starting')
  const finishingLabel = translate('runbooks:timelineSummary:summaryChart:finishing')
  const inProgressLabel = translate('runbooks:timelineSummary:summaryChart:inProgress')

  const unprocessedDonutData: DonutSection[] = [
    // Note: id needs to match the label as it's used for display when hovering donut segments
    {
      id: startingFinishingLabel,
      name: startingFinishingLabel,
      label: startingFinishingLabel,
      color: '#2A55C3',
      value: 0
    },
    { id: startingLabel, name: startingLabel, label: startingLabel, color: '#87a2e5', value: 0 },
    { id: finishingLabel, name: finishingLabel, label: finishingLabel, color: '#b6bfc4', value: 0 },
    { id: inProgressLabel, name: inProgressLabel, label: inProgressLabel, color: '#d8dddf', value: 0 }
  ]

  const { donutData, totalCount } = createDonutData(runbooks, selectedDates, unprocessedDonutData)

  // TODO remove when reactWorkspace is default
  const handleRunbookClick = (id: number) => {
    if (isReactWorkspaceEnabled) {
      openRightPanel({ type: 'runbook-edit', runbookId: id })
    } else {
      onSelectRunbook?.(id)
    }
  }

  return (
    <>
      {runbooks ? (
        <Summary data-testid="runbooks-timeline-summary">
          <PanelHeading>
            <Heading>{translate('runbooks:timelineSummary:heading')}</Heading>
            {dateRangeContent}
            <button onClick={() => onClickClose?.()}>
              <PanelCloseIcon name="close" size="24px" color={colors.secondaryGrey} />
            </button>
          </PanelHeading>
          <Box
            css={`
              padding: ${px2rem(16)};
            `}
          >
            {/* Note: fixed height to fit in the panel */}
            {/* TODO: verify resizing / other screens */}
            <DonutChart total centerValue={`${totalCount}`} data={donutData} height={85} />
          </Box>
          <SummaryContent>
            {runbooks?.map(runbook => (
              <Box
                key={runbook.id}
                tabIndex={0}
                role="button"
                onKeyPress={() => handleRunbookClick(runbook.id)}
                onClick={() => handleRunbookClick(runbook.id)}
              >
                <RunbooksSummaryItem runbook={runbook} />
              </Box>
            ))}
          </SummaryContent>
        </Summary>
      ) : null}
    </>
  )
}

const Summary = styled.div`
  overflow-y: auto;
`

const PanelHeading = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: space-between;
  padding: 0 7px 0 16px;
`

const Heading = styled.div`
  font-size: 22px;
  color: ${colors.primaryGreyHover};
`

const HeadingDate = styled.span`
  font-size: 14px;
  color: ${colors.primaryGrey};
`

const SummaryContent = styled.div`
  padding: 16px;
`

const PanelCloseIcon = styled(Icon)`
  ${transition('fast', 'color')}
  &:hover {
    color: ${colors.primaryGreyHover};
  }
`

// helper function to make donut details
export const createDonutData = (
  runbooks: RunbookData[] = [],
  selectedDates: SelectedDates = { start: null, end: null },
  unprocessedDonutData: DonutSection[] = []
) => {
  const donutData = runbooks.reduce((data, runbook) => {
    if (!runbook.end_display || !selectedDates.start || !selectedDates.end) {
      return unprocessedDonutData
    }

    if (runbook.start_display >= selectedDates.start && runbook.end_display <= selectedDates?.end) {
      // starting & finishing
      data[0].value += 1
    } else if (runbook.start_display >= selectedDates.start && runbook.start_display <= selectedDates.end) {
      // starting
      data[1].value += 1
    } else if (runbook.end_display >= selectedDates.start && runbook.end_display <= selectedDates.end) {
      // finishing
      data[2].value += 1
    } else if (runbook.start_display < selectedDates.start && runbook.end_display > selectedDates.end) {
      // inProgress
      data[3].value += 1
    }

    return data
  }, unprocessedDonutData)

  return {
    totalCount: donutData.map(segment => segment.value).reduce((segmentValue, total) => segmentValue + total, 0),
    // Update the label to include the count for each segment, eg "3 Finishing"
    donutData: donutData.map(segment => ({ ...segment, label: `${segment.value} ${segment.label}` }))
  }
}
