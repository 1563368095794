import { memo } from 'react'

import { AccordionPanel, Box, TextInput } from '@cutover/react-ui'
import { useLanguage } from 'main/services/hooks'
import { RunbookEditRunbook } from 'main/services/queries/types'
import { ConfigModel } from 'main/data-access'

export const RunbookEditWebhooksPanel = memo(({ runbook }: { runbook: RunbookEditRunbook }) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const config = ConfigModel.get()

  return (
    <AccordionPanel label={t('webhook.label')} icon="webhooks" iconColor={runbook.project?.color}>
      <Box margin={{ top: 'small' }}>
        <TextInput
          disabled // always
          label={t('webhook.runbookUri.label')}
          value={`${config.cutoverApiFullPath}/api/runbooks/${runbook.id}`}
        />
        <TextInput
          disabled // always
          label={t('webhook.runbookVersionUri.label')}
          value={`${config.cutoverApiFullPath}/api/runbooks/${runbook.id}/current_version`}
        />
      </Box>
    </AccordionPanel>
  )
})
