/* eslint-disable react-hooks/rules-of-hooks */

import { useRecoilCallback, useRecoilValue } from 'recoil'
import { pick } from 'lodash'

import { runbookRunbookTypeState, runbookTypesLookup, runbookTypesState, runbookTypeState } from 'main/recoil/runbook'
import { RunbookTypeModelType } from 'main/data-access/models/types'
import { RunbookTypeType } from 'main/services/queries/types'

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useRunbookType: RunbookTypeModelType['get'] = (identifier, keyOrKeys) => {
  const runbookType = useRecoilValue(runbookTypeState(identifier))
  return getRunbookTypeReturn(runbookType, keyOrKeys)
}

export const useRunbookTypeCallback: RunbookTypeModelType['getCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async (identifier: number) => {
    const runbookType = await snapshot.getPromise(runbookTypeState(identifier))
    return getRunbookTypeReturn(runbookType, keyOrKeys)
  })
}

export const useRunbookTypeCallbackSync: RunbookTypeModelType['getCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => (identifier: number) => {
    const runbookType = snapshot.getLoadable(runbookTypeState(identifier)).getValue()
    return getRunbookTypeReturn(runbookType, keyOrKeys)
  })
}

/* -------------------------------------------------------------------------- */
/*                                   Get All                                  */
/* -------------------------------------------------------------------------- */

export const useRunbookTypes: RunbookTypeModelType['getAll'] = keyOrKeys => {
  const runbookTypes = useRecoilValue(runbookTypesState)
  return getRunbookTypesReturn(runbookTypes, keyOrKeys)
}

export const useRunbookTypesCallback: RunbookTypeModelType['getAllCallback'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const runbookTypes = await snapshot.getPromise(runbookTypesState)
    return getRunbookTypesReturn(runbookTypes, keyOrKeys)
  })
}

export const useRunbookTypesCallbackSync: RunbookTypeModelType['getAllCallbackSync'] = keyOrKeys => {
  return useRecoilCallback(({ snapshot }) => () => {
    const runbookTypes = snapshot.getLoadable(runbookTypesState).getValue()
    return getRunbookTypesReturn(runbookTypes, keyOrKeys)
  })
}

/* -------------------------------------------------------------------------- */
/*                                 Get Lookup                                 */
/* -------------------------------------------------------------------------- */

export const useRunbookTypeLookup: RunbookTypeModelType['getLookup'] = () => {
  return useRecoilValue(runbookTypesLookup)
}

export const useRunbookTypeLookupCallback: RunbookTypeModelType['getLookupCallback'] = () => {
  return useRecoilCallback(({ snapshot }) => async () => {
    const runbookTypeLookup = await snapshot.getPromise(runbookTypesLookup)
    return runbookTypeLookup
  })
}

export const useRunbookTypeLookupCallbackSync: RunbookTypeModelType['getLookupCallbackSync'] = () => {
  return useRecoilCallback(({ snapshot }) => () => {
    const runbookTypeLookup = snapshot.getLoadable(runbookTypesLookup).getValue()
    return runbookTypeLookup
  })
}

/* -------------------------------------------------------------------------- */
/*                                  Get From                                  */
/* -------------------------------------------------------------------------- */

export const useRunbookTypeFrom: RunbookTypeModelType['getFrom'] = (scope, keyOrKeys) => {
  let runbookType = undefined
  switch (scope) {
    case 'active-runbook':
      runbookType = useRecoilValue(runbookRunbookTypeState)
    default:
      runbookType = useRecoilValue(runbookRunbookTypeState)
  }
  return getRunbookTypeReturn(runbookType, keyOrKeys)
}

export const useRunbookTypeFromCallback: RunbookTypeModelType['getFromCallback'] = (scope, keyOrKeys) => {
  return useRecoilCallback(({ snapshot }) => async () => {
    let runbookType = undefined
    switch (scope) {
      case 'active-runbook':
        runbookType = await snapshot.getPromise(runbookRunbookTypeState)
      default:
        runbookType = await snapshot.getPromise(runbookRunbookTypeState)
    }
    return getRunbookTypeReturn(runbookType, keyOrKeys)
  })
}

export const useRunbookTypeFromCallbackSync: RunbookTypeModelType['getFromCallbackSync'] = (scope, keyOrKeys) => {
  return useRecoilCallback(({ snapshot }) => () => {
    let runbookType = undefined
    switch (scope) {
      case 'active-runbook':
        runbookType = snapshot.getLoadable(runbookRunbookTypeState).getValue()
      default:
        runbookType = snapshot.getLoadable(runbookRunbookTypeState).getValue()
    }
    return getRunbookTypeReturn(runbookType, keyOrKeys)
  })
}

/* -------------------------------- Internal -------------------------------- */

const getRunbookTypeReturn = (runbookType: RunbookTypeType, keyOrKeys: any) => {
  if (!keyOrKeys) return runbookType
  if (Array.isArray(keyOrKeys)) return pick(runbookType, keyOrKeys)
  // @ts-ignore
  return runbookType[keyOrKeys]
}

const getRunbookTypesReturn = (runbookTypes: RunbookTypeType[], keyOrKeys: any) => {
  if (!keyOrKeys) return runbookTypes

  return runbookTypes.map(runbookType => {
    if (Array.isArray(keyOrKeys)) return pick(runbookType, keyOrKeys)
    // @ts-ignore
    return runbookType[keyOrKeys]
  })
}
