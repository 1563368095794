import { useEffect, useMemo, useState } from 'react'
import { eventManager } from 'event-manager'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Message,
  resolveColor,
  Text,
  Tooltip,
  useTheme
} from '@cutover/react-ui'
import { useAngularRightSidePanel, useLanguage } from 'main/services/hooks'
import {
  useValidateRunbook,
  ValidatedErrors,
  ValidatedObject,
  ValidatedWarnings,
  ValidateRunbookErrorResponse
} from 'main/services/queries/use-validate-runbook'
import { useRouting } from 'main/services/routing/hooks'
import { ConfigModel } from 'main/data-access'

type RunbookValidationProps = {
  closeModal: () => void
  isSnippet?: boolean
  runbookId?: string | number
  warnings?: ValidatedWarnings | null
  errors?: ValidatedErrors | null
  runbookVersionId?: string | number
  setIsValidating?: (isValidating: boolean) => void
  setErrorScore?: (errorScore: number) => void
}

export const RunbookValidation = ({
  closeModal,
  isSnippet,
  runbookId,
  runbookVersionId,
  warnings: initWarnings = null,
  errors: initErrors = null,
  setIsValidating,
  setErrorScore
}: RunbookValidationProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)
  const [warnings, setWarnings] = useState<ValidatedWarnings | null>(initWarnings)
  const [errors, setErrors] = useState<ValidatedErrors | null>(initErrors)
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null)
  const isReactRunbookEnabled = ConfigModel.isFeatureEnabled('react_runbook')
  const { accountId } = useParams()

  const { t } = useLanguage('runbook')
  const navigate = useNavigate()
  const theme = useTheme()
  const { toRunbook } = useRouting()

  const { isLoading: isValidating } = useValidateRunbook({
    runbookId,
    runbookVersionId,
    onError: response => {
      const { status } = response

      if (status === 422) {
        const { errors: responseErrors } = response as { errors: ValidateRunbookErrorResponse; status: 422 }
        const { errors, warnings } = responseErrors
        setWarnings(warnings)
        setErrors(errors)
      } else {
        const { errors: responseErrors } = response as { errors: { errors: string[] }; status: number }
        setErrorMessages(responseErrors?.errors)
      }
    }
  })

  useEffect(() => {
    setIsValidating?.(isValidating)
  }, [isValidating])

  const { errorScore, warningScore } = useMemo(
    () => ({ errorScore: errors ? countScore(errors) : 0, warningScore: warnings ? countScore(warnings) : 0 }),
    [errors, warnings]
  )

  useEffect(() => {
    setErrorScore?.(errorScore)

    if (errorScore > 0) {
      setIsDetailsOpen(true)
    }
  }, [errorScore])

  const errorCounts = errors ? Object.keys(errors).length : 0
  const warningCounts = warnings ? Object.keys(warnings).length : 0
  const totalCheckCount = errorCounts + warningCounts
  const totalScore = errorScore + warningScore

  const { openAngularSidePanel } = useAngularRightSidePanel()

  const toggleDetails = () => {
    setIsDetailsOpen(prevIsDetailsOpen => !prevIsDetailsOpen)
  }

  const onValidateItemClick = (item: ValidatedObject, type: string) => {
    switch (type) {
      case 'linked_runbooks': {
        closeModal()
        if (accountId) {
          navigate(toRunbook({ accountSlug: accountId, runbookId: item.id }))
        }
        break
      }
      case 'linked_tasks': {
        closeModal()
        if (accountId) {
          navigate(
            toRunbook({
              accountSlug: accountId,
              runbookId: item.id,
              // Redirect to linked task with linked runbook
              params: isReactRunbookEnabled ? '' : `?task=${item.internal_id}`
            })
          )
        }
        break
      }
      case 'tasks':
        closeModal()
        eventManager.emit('open-task-edit-panel', { taskId: item.id })
        break
      case 'rb_teams':
        closeModal()
        eventManager.emit('open-team-edit-panel', { teamId: item.id })
        break
      case 'change_requests':
        closeModal()
        openAngularSidePanel({ content: 'change-requests' })
        break
    }
  }

  if (isValidating) {
    return (
      <Box>
        <Text>{t('startRunbookModal.validationSummary.validating')}</Text>
        <ProgressBar>
          <Box background="bg-3" basis="100%" />
        </ProgressBar>
      </Box>
    )
  }

  return (
    <Box>
      {errorMessages && (
        <Box margin={{ bottom: '16px' }}>
          <Message type="error" message={errorMessages} />
        </Box>
      )}

      {totalScore !== null && (
        <Box>
          {totalScore > 0 && (
            <Text>
              {t('startRunbookModal.validationSummary.error', {
                item: isSnippet ? 'snippet' : 'runbook',
                counts: totalCheckCount - totalScore,
                totalCheckCount
              })}{' '}
              <Button
                plain
                onClick={toggleDetails}
                css={`
                  text-decoration: underline;
                  padding: 0;
                  min-width: 0;
                  color: ${resolveColor('primary', theme)};
                `}
              >
                {t('startRunbookModal.validationSummary.errorDetails', { action: isDetailsOpen ? 'hide' : 'show' })}
              </Button>
            </Text>
          )}
          {totalScore === 0 && !errorMessages && (
            <Text>
              {t('startRunbookModal.validationSummary.success', {
                item: isSnippet ? 'snippet' : 'runbook'
              })}
            </Text>
          )}
          {totalScore > 0 && (
            <Tooltip
              content={t('startRunbookModal.tooltipText.passed', {
                count: totalCheckCount - totalScore,
                score: totalScore
              })}
              placement="bottom"
            >
              <ProgressBar>
                <Box background="success" basis={100 * ((totalCheckCount - totalScore) / totalCheckCount) + '%'} />
                <Box background="rag-amber" basis={100 * (warningScore / totalCheckCount) + '%'} />
                <Box background="error" basis={100 * (errorScore / totalCheckCount) + '%'} />
              </ProgressBar>
            </Tooltip>
          )}
          {totalScore === 0 && !errorMessages && (
            <Tooltip content={t('startRunbookModal.tooltipText.allPassed')} placement="bottom">
              <ProgressBar>
                <Box background="success" basis="100%" />
              </ProgressBar>
            </Tooltip>
          )}
        </Box>
      )}
      {isDetailsOpen && (
        <Box>
          {errorScore > 0 && (
            <Box margin={{ top: '22px' }}>
              <Text css="font-weight: 600;">{t('startRunbookModal.errorsTitle')}</Text>
              {errors && (
                <Accordion>
                  {Object.keys(errors).map(key => (
                    <AccordionPanel headingLevel={3} icon="alert" iconColor="error" label={errors[key].label} key={key}>
                      <Box css="display: block; max-height: 150px; overflow: auto; padding-left: 38px;">
                        {errors[key].objects?.map((item, index) => (
                          <ItemName
                            key={item.id}
                            onClick={() => onValidateItemClick(item, errors[key].type)}
                            type={errors[key].type}
                            data-cy="runbook-start-error-details"
                          >
                            <>
                              {index !== 0 ? ', ' : ''}
                              {item.name}
                            </>{' '}
                          </ItemName>
                        ))}
                      </Box>
                    </AccordionPanel>
                  ))}
                </Accordion>
              )}
            </Box>
          )}
          {warningScore > 0 && (
            <Box margin={{ top: '22px' }}>
              <Text css="font-weight: 600;">
                {t('startRunbookModal.warningsTitle', {
                  item: isSnippet ? 'Snippet' : 'Runbook'
                })}
              </Text>
              {warnings && (
                <Accordion>
                  {Object.keys(warnings).map(key => (
                    <AccordionPanel
                      headingLevel={3}
                      icon="alert"
                      iconColor="rag-amber"
                      label={warnings[key].label}
                      key={key}
                    >
                      <Box css="display: block; max-height: 150px; overflow: auto; padding-left: 38px;">
                        {warnings[key].objects?.map((item, index) => (
                          <ItemName
                            key={item.id}
                            onClick={() => onValidateItemClick(item, warnings[key].type)}
                            type={warnings[key].type}
                          >
                            <>
                              {index !== 0 ? ', ' : ''}
                              {item.name}
                            </>
                          </ItemName>
                        ))}
                      </Box>
                    </AccordionPanel>
                  ))}
                </Accordion>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const countScore = (errorsObject: ValidatedErrors) => Object.keys(errorsObject).length

const ProgressBar = styled(Box)`
  flex-direction: row;
  overflow: hidden;
  margin-top: 12px;
  width: 100%;
  height: 14px;
  border-radius: 15px;
`

const ItemName = styled(Text)<{ type: string }>`
  cursor: ${({ type }) => (type === 'users' ? 'default' : 'pointer')};
`
